import React, {useEffect} from 'react';
import tos from "./tos.pdf";
import {Link} from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import DesktopView from "./DesktopView";


function Home() {
    const playStoreLink = "https://play.google.com/store/apps/details?id=life.meetz";
    // const appStoreLink = "https://www.apple.com/app-store/";
    useEffect(() => {
        const url = window.location.href;

        if (url.includes('?tos')) {
            const link = document.createElement('a');
            link.href = tos;
            link.setAttribute('download', "Terms Of Service");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }, []);
    return (
      <>
        <div className="mobileContainer">
            <div className="container">
                <div className="nav">
                    <div className="logo" ></div>
                    <div className="navButtonsContainer">
                        <a href="#about" style={{textDecoration: 'none'}}>
                        <div className="navButtons">About</div>
                        </a>
                        <a href="#safety" style={{textDecoration: 'none'}}>
                        <div className="navButtons" >Safety</div>
                        </a>
                    </div>
                </div>
                <div className="wrap">
                    <div className="text">Let's Meetz new friends</div>
                    <a href={playStoreLink}  rel="noopener noreferrer" target="_blank" className="hrefContainerButtons">
                     <div className="download-buttons-container">
                        <div className="playStoreButton" />
                         <div className="playStoreButtonText">GET ON PLAY STORE</div>

                         {/*<a href={appStoreLink} rel="noopener noreferrer" target="_blank">*/}
                        {/*  <div className="appleButton"/>*/}
                        {/*</a>*/}
                     </div>
                    </a>
                    <div className="textApp">Discover a world of connections with Meetz - your passport to meeting new friends worldwide! Connecting, bonding, and creating memories, all in one place.</div>
                </div>
                <div className="circleContainer"/>
                <div className="sectionContainer">
                    <div className="infoContainer" id="about">
                        <ScrollAnimation  animateIn='animate__zoomIn' className="text">Try out all the ways to find friends</ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn' className="infoItemContainer">
                            <div className="check"/>
                            <div className="infoItemText">Chat or talk live with a randomly matched person for 10 minutes</div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn' className="infoItemContainer">
                            <div className="check"/>
                            <div className="infoItemText">Suggest being friends if the person you're matched with seems like a great friend</div>
                        </ScrollAnimation>
                    </div>
                    <ScrollAnimation animateIn='animate__bounceIn' duration="2" className="photoInfo"></ScrollAnimation>
                    <div className="infoContainer">
                        <ScrollAnimation  animateIn='animate__zoomIn'  className="text">Share your passions</ScrollAnimation>
                        <ScrollAnimation  animateIn='animate__zoomIn'  className="infoItemContainer">
                            <div className="check"/>
                            <div className="infoItemText">Discover people who inspire you</div>
                        </ScrollAnimation>
                        <ScrollAnimation  animateIn='animate__zoomIn' className="infoItemContainer">
                            <div className="check"/>
                            <div className="infoItemText">Be part of something bigger</div>
                        </ScrollAnimation>
                        <ScrollAnimation  animateIn='animate__zoomIn' className="infoItemContainer">
                            <div className="check"/>
                            <div className="infoItemText">Uncover new passions</div>
                        </ScrollAnimation>
                    </div>
                        <div className="animationContainer">
                            <div className="diversityContainer">
                             <ScrollAnimation animateIn='animate__bounceInLeft' duration="1.5" className="diversity" />
                            </div>
                            <div className="beYourselfContainer">
                                <ScrollAnimation animateIn='animate__bounceInRight' duration="1.5"  className="beYourself"/>
                            </div>
                            <ScrollAnimation animateIn='animate__zoomIn' className="photoInfo2"></ScrollAnimation>
                            <ScrollAnimation animateIn='animate__zoomIn' className="textMuchMore" >And much more!</ScrollAnimation>
                        </div>
                    <div className="infoContainer" id="safety">
                        <ScrollAnimation animateIn='animate__zoomIn'  className="text">Safety</ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn'  className="textApp">Your safety is our top priority. That's why we create an environment where you can confidently make new friends. Learn more about our safeguards and guidelines.</ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn' className="safetyItems">
                            <div className="titleSafety">Profile Verification</div>
                            <div className="textSafety">All users are encouraged to verify their profiles by confirming their contact information.</div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn' className="safetyItems">
                            <div className="titleSafety">Mutual Photo Consent</div>
                            <div className="textSafety">Photos can be sent only after mutual agreement, ensuring a comfortable experience.</div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn' className="safetyItems">
                            <div className="titleSafety">Privacy Options</div>
                            <div className="textSafety">We give you control over your privacy. You can customize the visibility of your profile and information according to your preferences.</div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn' className="safetyItems">
                            <div className="titleSafety">Nudity Detection Algorithm</div>
                            <div className="textSafety">Our app employs an advanced algorithm to detect and prevent the sharing of explicit content, enhancing user safety.</div>
                        </ScrollAnimation>
                    </div>
                    <div className="infoContainer" >
                        <ScrollAnimation animateIn='animate__zoomIn'  className="text">Safety Tips</ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn'  className="textApp">We want to share practical tips that will help you fully enjoy safe interactions with other users. Our application provides you not only with great opportunities to make new connections but also ensures that your experience is full of trust and comfort.</ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn' className="safetyItemsCloud">
                            <div className="titleSafetyCloud">Trust Your Instincts</div>
                            <div className="textSafetyCloud">If something seems off, don't hesitate to step back or end the conversation.</div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn' className="safetyItemsCloud">
                            <div className="titleSafetyCloud">Protect Personal Info</div>
                            <div className="textSafetyCloud">Keep your personal details private, such as phone number and address. Your safety matters.</div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn' className="safetyItemsCloud">
                            <div className="titleSafetyCloud">Meet in Public</div>
                            <div className="textSafetyCloud"> If meeting in person, choose well-lit, public places. Let someone close know about your plans.</div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='animate__zoomIn' className="safetyItemsCloud">
                            <div className="titleSafetyCloud">Report Suspicious Behavior</div>
                            <div className="textSafetyCloud">If you encounter suspicious behavior, report it to our support team. Safety is our priority.</div>
                        </ScrollAnimation>
                    </div>
                    <div className="infoContainer">
                        <ScrollAnimation animateIn='animate__zoomIn'  className="text">Support</ScrollAnimation>
                        <div  className="textApp">If you need assistance or have any questions, feel free to contact our support team via email at <a style={{textDecoration: 'none',  background: '-webkit-linear-gradient(90deg, #fff7ad, #ffa9f9 )',
                            '-webkit-background-clip': 'text',
                            '-webkit-text-fill-color': 'transparent' }} href="mailto:support@meetz.life">support@meetz.life</a> <br/>We're here to help you!</div>
                    </div>
                    </div>
            </div>
            <div className="footer">
                <div className="containerTermsFooter">
                    <Link to="/terms-of-service" className="textTerms">
                        Terms of Service
                    </Link>
                    <Link to="/privacy-policy" className="textTerms">
                        Privacy Policy
                    </Link>
                </div>
                <div className="containerIcons">
                    <a href="https://www.facebook.com/meetz.life/" rel="noopener noreferrer" target="_blank">
                        <svg className="icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg>
                    </a>
                    {/*<a href="https://www.facebook.com/meetz.life/" rel="noopener noreferrer" target="_blank">*/}
                    {/*    <svg className="icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>*/}
                    {/*</a>*/}
                </div>
            </div>
        </div>
          <DesktopView/>
      </>
    );
}

export default Home;
