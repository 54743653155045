// TermsOfService.js
import React from 'react';
import {Link} from "react-router-dom";

function TermsOfService() {
    return (
        <div className="containerTerms">
          <Link to="/" className="logoTerms" ></Link>
          <h2 className="textTermsTitle">REGULAMIN APLIKACJI MEETZ</h2>
          <div className="textTermsP">Dziękujemy za zainteresowanie naszą aplikacją (dalej jako: <span className="spanBold">„Aplikacja”, „Aplikacja Meetz”</span>) dostępną na
            urządzeniach mobilnych z systemem operacyjnym Android/iOS. <br/><br/>
            Prosimy o uważne zapoznanie się z niniejszym dokumentem. Regulamin określa zasady korzystania z Aplikacji
            Meetz przez jej użytkowników, w tym warunki licencyjne oraz politykę prywatności danych osobowych
            gromadzonych i przetwarzanych w ramach Aplikacji. Aplikacja Meetz chroniona jest przepisami prawa
            autorskiego, prawa własności intelektualnej oraz innymi właściwymi, bezwzględnie wiążącymi przepisami prawa.
            Korzystanie z Aplikacji Meetz możliwe jest na warunkach licencyjnych określonych poniżej. <br/><br/>
            Formuła niniejszego dokumentu zakłada ustalenie ogólnych zasad i warunków korzystania z Aplikacji. Niniejsze
            warunki, w razie podjęcia decyzji przez użytkownika o korzystaniu z Aplikacji Meetz, regulują w szczególności
            zasady korzystania z Aplikacji, w tym kwestie naszej odpowiedzialności.<br/><br/>
          </div>
          <div className="textTermsPbold"> Zapraszamy do zapoznania się z regulaminem, <br/><br/> Zespół Aplikacji Meetz</div>
          <div className="hrTerms"/>
          <h2 className="textTermsTitle">1) POSTANOWIENIA OGÓLNE</h2>
          <ol>
            <li className="textTermsP">Właścicielem Aplikacji Meetzjestspółka MEETZ SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ z siedzibą
              w Poznaniu (adres siedziby i adres do korespondencji: ul. Juliusza Słowackiego 55/1, 60-521 Poznań),
              wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS: 0001051530; sąd
              rejestrowy, w którym przechowywana jest dokumentacja spółki: Sąd Rejonowy Poznań – Nowe Miasto i
              Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego; kapitał zakładowy w wysokości:
              5000,00 zł; NIP 7812055176, REGON 526056312, adres poczty elektronicznej:<a href="mailto:support@meetz.life">support@meetz.life</a>oraz
              numer telefonu: 888 515 811 (dalej jako: <span className="spanBold">„Usługodawca”</span>)</li>
            <li className="textTermsP"> Usługodawca prowadzi Aplikację Meetz oraz jest odpowiedzialny wyłącznie za prawidłowe działanie Usług
              Elektronicznych oraz innych funkcjonalności Aplikacji zgodnie z informacjami przedstawionymi w niniejszym
              Regulaminie. Obok Usługodawcy występują również Użytkownicy – będący samodzielnymi podmiotami
              trzecimi w stosunku do Usługodawcy. Z poziomu Aplikacji Użytkownicy mogą korzystać z dostarczanych im
              Usług Elektronicznych oraz pozostałych funkcjonalności Aplikacji na warunkach wskazanych w niniejszym
              Regulaminie.</li>
            <li className="textTermsP">
              Aplikacja Meetz przeznaczona jest wyłącznie do zastosowania w celach niekomercyjnych, tj. na własny,
              prywatny użytek Użytkownika. Regulamin skierowany jest do wszystkich osób korzystających z Aplikacji
              Meetz, chyba że dane postanowienie stanowi inaczej. Postanowienia niniejszego Regulaminu nie mają na
              celu wyłączać ani ograniczać jakichkolwiek praw konsumentów przysługujących im na mocy bezwzględnie
              wiążących przepisów prawa. W przypadku niezgodności postanowień niniejszego Regulaminu z powyższymi
              przepisami, pierwszeństwo przysługuje tym przepisom.
            </li>
            <li className="textTermsP">
              Określenia użyte w niniejszym Regulaminie i rozpoczynające się wielką literą mają następujące znaczenia:
              <ol type="a">
                <li className="textTermsP"><span className="spanBold">APLIKACJA MEETZ, APLIKACJA</span> – oprogramowanie wraz z elementami niebędącymi programem
                  komputerowym w rozumieniu przepisów Prawa Autorskiego, ale stanowiącymi jego integralną część,
                  umożliwiające Użytkownikowi korzystanie z niego na urządzeniu mobilnym spełniającym wymogi
                  przewidziane niniejszym Regulaminem.</li>
                <li className="textTermsP"><span className="spanBold">CENNIK</span> – cennik Konta Premium lub innych odpłatnych Usług Elektronicznych (mikropłatności)
                  znajdujący się w odpowiedniej zakładce Aplikacji Meetz.</li>
                <li className="textTermsP"><span className="spanBold">CZAT</span> – Usługa Elektroniczna, rodzaj komunikatora online umożliwiającego korzystającym z niej
                  Użytkownikom prowadzenie rozmów za pośrednictwem Aplikacji. W zależności od wyboru
                  Użytkownika Czat może być prowadzony poprzez wymianę wiadomości tekstowych („Czat
                  Tekstowy”) bądź też w formie wideorozmowy („Czat Wideo”).
                </li>
                <li className="textTermsP"><span className="spanBold">KODEKS CYWILNY</span> – ustawa Kodeks cywilny z dnia 23 kwietnia 1964 r. (t.j. Dz.U. z 2022 r. poz. 1360
                  ze zm.).</li>
                <li className="textTermsP"><span className="spanBold">KONTO PREMIUM, WERSJA PREMIUM</span> – odpłatna wersja Konta Użytkownika dostępna w modelu
                  subskrypcyjnym na warunkach wskazanych w Regulaminie oraz Cenniku. Wersja Premium zapewnia
                  Użytkownikowi Aplikacji dodatkowe udogodnienia, korzyści i funkcjonalności niedostępne
                  standardowo w ramach Aplikacji. Szczegółowe informacje o Koncie Premium wraz z kosztem jego
                  aktywacji znaleźć można w Cenniku.</li>
                <li className="textTermsP"><span className="spanBold">KONTO UŻYTKOWNIKA, KONTO</span> – Usługa Elektroniczna, oznaczony indywidualnym adresem poczty
                  elektronicznej (adres e-mail) lub numerem telefonu Użytkownika zbiór zasobów w systemie
                  teleinformatycznym Aplikacji Meetz, w którym gromadzone są dane podane przez Użytkownika oraz
                  informacje o działaniach Użytkownika podejmowanych w ramach Aplikacji.
                </li>
                <li className="textTermsP"><span className="spanBold">KREDYTY</span> – wirtualny odpowiednik środka płatniczego, dostępny wyłącznie w Aplikacji Meetz oraz
                  służący jedynie do regulowania płatności za aktywację określonych funkcjonalności i zasobów
                  Aplikacji. Kredyty nie stanowią środka płatniczego poza Aplikacją oraz nie podlegają wypłacie w innej
                  walucie. Szczegółowe informacje o Kredytach wraz z kosztem ich doładowania znaleźć można w
                  Cenniku</li>
                <li className="textTermsP"><span className="spanBold">PRAWO AUTORSKIE</span> – ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (t.j.
                  Dz.U. z 2022 r. poz. 2509).</li>
                <li className="textTermsP"><span className="spanBold">REGULAMIN</span> – niniejszy regulamin Aplikacji Meetz wraz z załącznikami stanowiącymi jego integralną
                  część.
                </li>
                <li className="textTermsP"><span className="spanBold">USŁUGA ELEKTRONICZNA</span> – usługa świadczona drogą elektroniczną przez Usługodawcę na rzecz
                  Użytkowników za pośrednictwem Aplikacji Meetz zgodnie z niniejszym Regulaminem.</li>
                <li className="textTermsP"><span className="spanBold">USŁUGODAWCA</span> – spółka MEETZ SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ z siedzibą w
                  Poznaniu (adres siedziby i adres do korespondencji: ul. Juliusza Słowackiego 55/1, 60-521 Poznań),
                  wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS:
                  0001051530; sąd rejestrowy, w którym przechowywana jest dokumentacja spółki: Sąd Rejonowy
                  Poznań – Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego;
                  kapitał zakładowy w wysokości: 5000,00 zł; NIP 7812055176, REGON 526056312, adres poczty
                  elektronicznej: <a href="mailto:support@meetz.life">support@meetz.life</a> oraz numer telefonu: 888 515 811.</li>
                <li className="textTermsP"><span className="spanBold">USTAWA O PRAWACH KONSUMENTA</span> – ustawa z dnia 30 maja 2014 r. o prawach konsumenta (t.j.
                  Dz.U. z 2020 r. poz. 287 ze zm.).
                </li>
                <li className="textTermsP"><span className="spanBold">UŻYTKOWNIK</span> – każda osoba fizyczna posiadająca pełną zdolność do czynności prawnych, a w
                  wypadkach przewidzianych przez przepisy powszechnie obowiązujące także osoba fizyczna
                  posiadająca ograniczoną zdolność do czynności prawnych (tj. posiadająca co najmniej 13 lat), która
                  korzysta lub zamierza korzystać z Aplikacji Meez.</li>
                <li className="textTermsP"><span className="spanBold">WIRTUALNY PRZYJACIEL</span> – Usługa Elektroniczna umożliwiająca korzystającym z niej Użytkownikom
                  rozmowę za pośrednictwem Aplikacji z chatbotem działającym w oparciu o oprogramowanie
                  sztucznej inteligencji udostępniane przez firmę trzecią Open AI zgodnie z zasadami korzystania
                  dostępnymi pod adresem: https://openai.com/policies/usage-policies.
                </li>
              </ol>
            </li>
          </ol>
          <h2 className="textTermsTitle">2) OGÓLNE WARUNKI KORZYSTANIA Z APLIKACJI MEETZ</h2>
          <ol>
            <li className="textTermsP">Aplikacja Meetz ma charakter społecznościowy, co oznacza, że podstawą funkcjonowania Aplikacji są jej
              Użytkownicy oraz udostępniane przez nich treści. Aplikacja Meetz służy przede wszystkim do
              komunikowania się za jej pomocą, w tym poszukiwania, nawiązywania i budowania relacji między
              Użytkownikami.</li>
            <li className="textTermsP">Użytkownik obowiązany jest do korzystania z Aplikacji Meetz zgodnie z jej tematyką, przeznaczeniem oraz
              niniejszym Regulaminem, w sposób zgodny z prawem i dobrymi obyczajami, mając na uwadze
              poszanowanie dóbr osobistych oraz praw autorskich i własności intelektualnej Usługodawcy, innych
              Użytkowników oraz osób trzecich. Użytkownik obowiązany jest do wprowadzania danych zgodnych ze
              stanem faktycznym. Użytkownika obowiązuje zakaz dostarczania treści o charakterze bezprawnym.
              Użytkownikowi zabrania się wykorzystywania Aplikacji do przesyłania niezamówionych informacji
              handlowych (spam). Użytkownikowi zakazuje się również podejmowania działań, których celem lub
              skutkiem jest zakłócenie prawidłowego funkcjonowania Aplikacji.</li>
            <li className="textTermsP">Usługodawca Aplikacji Meetz udostępnia wyłącznie narzędzie techniczne, jednak nie jest zobowiązany do
              weryfikacji, w jakim celu Użytkownicy faktycznie korzystają z Aplikacji. Usługodawca podejmuje należyte
              starania, aby korzystanie z Aplikacji było zrozumiałe i przejrzyste dla Użytkowników, lecz nie może
              zagwarantować, że każdy Użytkownik będzie potrafił obsługiwać samodzielnie Aplikację oraz że okaże się
              ona przydatna w osiągnięciu celów oczekiwanych przez Użytkownika. W najszerszym zakresie
              dopuszczalnym przez przepisy właściwego prawa, przyjmuje się, że Usługodawca udostępnia Aplikację, w
              tym wszystkie jej funkcjonalności i zasoby, w formie, w jakiej została ona dostarczona („taka, jaka jest”) i
              nie czyni żadnych dorozumianych lub wyrażonych otwarcie zapewnień co do jej jakości lub użyteczności do
              konkretnych zastosowań.
            </li>
            <li className="textTermsP">Usługodawca przestrzega art. 14 ust. 1 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
              elektroniczną (t.j. Dz.U. z 2020 r. poz. 344), zgodnie z którym nie ponosi odpowiedzialności za
              przechowywane w Aplikacji dane Użytkownika, jeśli nie wie o bezprawnym charakterze tych danych lub
              związanej z nimi działalności, a w razie otrzymania urzędowego zawiadomienia lub uzyskania wiarygodnej
              wiadomości o bezprawnym charakterze danych lub związanej z nimi działalności niezwłocznie uniemożliwi
              do nich dostęp.</li>
            <li className="textTermsP">. Korzystanie z Aplikacji Meetz wiąże się ze standardowymi zagrożeniami występującymi w sieci Internet.
              Podstawowym zagrożeniem każdego użytkownika Internetu, w tym osób korzystających z Usług
              Elektronicznych, jest możliwość „zainfekowania” systemu teleinformatycznego przez różnego rodzaju
              oprogramowanie tworzone głównie w celu wyrządzania szkód lub uzyskania nieuprawnionego dostępu do
              danych Użytkownika. Dlatego w celu uniknięcia zagrożeń z tym związanych, Usługodawca zaleca, aby
              Użytkownik dbał o bieżącą aktualizację systemu operacyjnego, oprogramowania antywirusowego oraz
              pozostałych zabezpieczeń swojego urządzenia końcowego.</li>
            <li className="textTermsP"> Administratorem danych osobowych przetwarzanych w Aplikacji Meetz w związku z realizacją postanowień
              niniejszego Regulaminu jest Usługodawca. Dane osobowe przetwarzane są w celach, przez okres i w oparciu
              o podstawy i zasady wskazane w <span className="spanBold">polityce prywatności stanowiącej załącznik nr 1</span> do niniejszego
              Regulaminu. Polityka prywatności zawiera przede wszystkim zasady dotyczące przetwarzania danych
              osobowych przez Usługodawcę w Aplikacji, w tym podstawy, cele i okres przetwarzania danych osobowych
              oraz prawa osób, których dane dotyczą. Korzystanie z Aplikacji, w tym dokonywanie w niej zakupów, jest
              dobrowolne. Podobnie związane z tym podanie danych osobowych przez Użytkownika korzystającego z
              Aplikacji jest dobrowolne, z zastrzeżeniem wyjątków wskazanych w polityce prywatności (np. obowiązki
              umowne lub ustawowe Usługodawcy).</li>
          </ol>
          <h2 className="textTermsTitle">3) SZCZEGÓŁOWE WARUNKI KORZYSTANIA Z APLIKACJI MEETZ</h2>
          <ol>
            <li className="textTermsP">Aplikacja Meetz jest dostępna do pobrania w serwisach i aplikacjach Google Play oraz App Store.</li>
            <li className="textTermsP">Warunkiem rozpoczęcia korzystania z Aplikacji jest uprzednie pobranie i zainstalowanie Aplikacji na
              urządzeniu spełniającym wskazane w Regulaminie minimalne wymogi techniczne.</li>
            <li className="textTermsP">Minimalne wymagania techniczne niezbędne do prawidłowego uruchomienia i korzystania z Aplikacji: (1)
              tablet, smartfon lub inne urządzenie mobilne z aktywnym dostępem do Internetu; (2) aktualnie wspierana
              wersja systemu iOS lub Android; oraz (3) dostęp do poczty elektronicznej.</li>
            <li className="textTermsP">Koszty transmisji danych podczas pobierania i korzystania z Aplikacji pokrywa Użytkownik we własnym
              zakresie na podstawie umowy zawartej z podmiotem świadczącym usługi tego typu.</li>
            <li className="textTermsP">Korzystanie z Aplikacji Meetzjest bezpłatne, z wyłączeniem dodatkowych korzyści przewidzianych dla Konta
              Premium. Szczegółowe informacje o odpłatnych funkcjonalnościach Aplikacji dostępne są w trakcie
              korzystania z Aplikacji, w tym również w Cenniku.</li>
            <li className="textTermsP">Użytkownik może w każdej chwili i bez podania przyczyny zaprzestać korzystania z Aplikacji poprzez jej
              samodzielne odinstalowanie lub usunięcie ze swojego urządzenia zgodnie z instrukcją obsługi danego
              urządzenia, z zastrzeżeniem, że usunięcie Aplikacji z urządzenia nie jest równoznaczne z usunięciem Konta
              Użytkownika.</li>
          </ol>
          <h2 className="textTermsTitle">4) FUNKCJONALNOŚCI APLIKACJI MEETZ</h2>
          <ol>
            <li className="textTermsP">Korzystać z Aplikacji Meetz na warunkach wskazanych w Regulaminie może każdy Użytkownik.</li>
            <li className="textTermsP">Korzystanie z Aplikacji w pełnym zakresie wymaga posiadania Konta Użytkownika. Użytkownik
              nieposiadający Konta może jedynie przeglądać główny ekran logowania Aplikacji, bez dostępu do
              pozostałych funkcjonalności i zasobów.
            </li>
            <li className="textTermsP"> Po utworzeniu Konta Użytkownika możliwe jest korzystanie w szczególności z następujących
              funkcjonalności oraz zasobów Aplikacji Meetz:
              <ol type="a">
                <li className="textTermsP">Profil Użytkownika – w tym zamieszczanie treści i edycja danych w ramach swojego Profilu; </li>
                <li className="textTermsP">Tablica Użytkownika – w tym dodawanie postów i komentarzy oraz wyświetlanie postów i
                  komentarzy dodanych przez innych Użytkowników;</li>
                <li className="textTermsP">Wyszukiwanie i przeglądanie profili Użytkowników, dopasowanie wyników wyszukiwania do
                  preferencji Użytkownika i ustawień jego profilu, przesyłanie innym Użytkownikom zaproszeń do listy
                  znajomych lub akceptacja ich zaproszeń;</li>
                <li className="textTermsP">Rozmowa z innymi Użytkownikami za pomocą Czatu, w tym Czatu Tekstowego lub Czatu Wideo;</li>
                <li className="textTermsP"> Dokonywanie zakupów w Aplikacji, w tym subskrypcja Wersji Premium lub doładowanie Kredytów
                  na aktywację wybranych funkcjonalności i zasobów (mikropłatności);
                </li>
                <li className="textTermsP">Otrzymywanie powiadomień push np. o zmianach i nowościach w Aplikacji, w zależności od zakresu
                  zgód marketingowych udzielonych przez Użytkownika i ustawienia uprawnień w jego urządzeniu
                  końcowym.</li>
              </ol>
            </li>
            <li className="textTermsP">Szczegółowy opis funkcjonalności Aplikacji, jej Usług Elektronicznych oraz zasad ich działania dostępny jest
              w ramach komunikatów, objaśnień i informacji kontekstowych wyświetlanych w trakcie korzystania z
              Aplikacji przez Użytkownika.
            </li>
          </ol>
          <h2 className="textTermsTitle">5) KORZYSTANIE Z KONTA UŻYTKOWNIKA</h2>
          <ol>
            <li className="textTermsP">Usługa Elektroniczna Konto Użytkownika świadczona jest nieodpłatnie i przez czas nieokreślony.</li>
            <li className="textTermsP">Utworzenie Konta następuje po wykonaniu kilku kolejnych kroków:
              <ol type="a">
                <li className="textTermsP"><span className="spanBold">Weryfikacja Użytkownika</span> – może być dokonana poprzez zalogowanie do konta Google albo poprzez
                  podanie kodu weryfikacyjnego przesłanego automatycznie na podany adres poczty elektronicznej
                  lub numer telefonu, w zależności od wyboru Użytkownika.
                </li>
                <li className="textTermsP"><span className="spanBold">Podanie podstawowych informacji na temat Użytkownika</span> –  w celu utworzenia Konta niezbędne
                  jest podanie podstawowych danych dotyczących Użytkownika, takich jak imię, data urodzenia, płeć
                  czy zdjęcie profilowe.</li>
                <li className="textTermsP"><span className="spanBold">Uzupełnienie dodatkowych informacji pozwalających na stworzenie profilu Użytkownika</span> – z chwilą
                  utworzenia Konta dla Użytkownika utworzona zostaje strona profilowa (profil), która ma charakter
                  publiczny i może być widoczna dla innych Użytkowników przeglądających Aplikację. Użytkownik
                  samodzielnie i dobrowolnie decyduje o zakresie informacji, jakie pragnie ujawnić na swój temat w
                  Aplikacji, przy czym ich rzetelne i szczegółowe uzupełnienie ma w założeniu przyspieszyć i ułatwić
                  skojarzenie Użytkownika z innymi Użytkownikami Aplikacji charakteryzującymi się podobnymi
                  preferencjami i zainteresowaniami.</li>
              </ol>
            </li>
            <li className="textTermsP">Korzystanie z Konta oraz poszczególnych funkcjonalności Aplikacji może wymagać podania również innych
              danych przez Użytkownika – każdorazowo informacja o zakresie wymaganych danych podana jest
              w Aplikacji, przed skorzystaniem z określonej funkcjonalności.</li>
            <li className="textTermsP">Użytkownik obowiązany jest do bieżącej aktualizacji danych podanych w ramach swojego Konta w razie ich
              zmiany.</li>
            <li className="textTermsP">Użytkownik może posiadać wyłącznie jedno Konto w tym samym czasie.</li>
            <li className="textTermsP">Użytkownik obowiązany jest do utrzymania danych dostępowych do Konta w tajemnicy przed osobami
              trzecimi. Użytkownik nie jest uprawniony do udzielania dostępu do Konta innym osobom, w tym również
              poprzez najem lub użyczenie Konta.</li>
            <li className="textTermsP">Odinstalowanie lub usunięcie Aplikacji nie powoduje automatycznego usunięcia Konta – w celu jego
              usunięcia Użytkownik może w każdej chwili i bez podania przyczyny przesłać Usługodawcy stosowne
              żądanie, w szczególności za pośrednictwem poczty elektronicznej na adres e-mail: <a href="mailto:support@meetz.life">support@meetz.life</a> albo
              korzystając z odpowiedniej opcji dostępnej z poziomu Aplikacji w ustawieniach swojego Konta. Usunięcie
              Konta następuje w takim wypadku po upływie 7 dni kalendarzowych od otrzymania stosownego żądania od
              Użytkownika. Jeśli Użytkownik zaloguje się w międzyczasie ponownie na swoje Konto, żądanie usunięcia
              Konta zostaje uznane za wycofane i wymaga jego ponowienia przez Użytkownika w taki sam sposób jak za
              pierwszym razem.
            </li>
            <li className="textTermsP">Usługodawca zastrzega sobie prawo zawieszenia, a w ostateczności także usunięcia Konta Użytkownika
              wyłącznie z ważnych powodów – tj. na wypadek rażącego lub uporczywego naruszenia postanowień
              Regulaminu, w szczególności gdy:
            <ol type="a">
              <li className="textTermsP">działania danego Użytkownika naruszają bezprawnie renomę Usługodawcy lub Aplikacji; </li>
              <li className="textTermsP">Użytkownik korzysta z Aplikacji niezgodnie z jej przeznaczeniem, tematyką lub z przekroczeniem
                zakresu udzielonej licencji na korzystanie z Aplikacji;
              </li>
              <li className="textTermsP">działania Użytkownika stwarzają zagrożenie bezpieczeństwa systemu teleinformatycznego
                Usługodawcy i Aplikacji lub bezpieczeństwa innych Użytkowników podczas korzystania przez nich z
                Aplikacji;</li>
              <li className="textTermsP">Użytkownik dostarcza w Aplikacji treści bezprawne, wulgarne i obraźliwe, treści naruszające cudze
                dobra osobiste i prawa autorskie, treści promujące pośrednio bądź bezpośrednio inne aplikacje,
                strony internetowe, produkty i usługi konkurencyjne wobec Aplikacji;
              </li>
              <li className="textTermsP">Użytkownik zalega z jakimikolwiek wymagalnymi płatnościami na rzecz Usługodawcy.</li>
            </ol>
            </li>
            <li className="textTermsP">Zawieszenie Konta polega na wyłączeniu Użytkownikowi możliwości logowania do Konta. W czasie
              zawieszenia nie jest możliwe korzystanie z jakichkolwiek funkcjonalności i zasobów Konta. Zawieszenie
              Konta może nastąpić na czas określony wskazany przez Usługodawcę lub nieokreślony – do czasu ustania
              przyczyny jego zawieszenia. W czasie zawieszenia Konta Użytkownik powinien podjąć działania mające na
              celu usunięcie przyczyny, która była podstawą zawieszenia, a po jej usunięciu obowiązany jest
              poinformować o tym niezwłocznie Usługodawcę. Usługodawca aktywuje Konto niezwłocznie po
              potwierdzeniu ustania przyczyny jego zawieszenia.</li>
            <li className="textTermsP">Usługodawca przed podjęciem decyzji o zawieszeniu Konta w miarę możliwości wzywa Użytkownika do
              zaprzestania naruszeń, a jeżeli wezwanie okaże się bezskuteczne lub niemożliwe – Usługodawca może
              zawiesić Konto. Usługodawca może także zawiesić Konto bez uprzedniego wezwania, gdy wymaga tego
              potrzeba przeciwdziałania dalszym trwającym naruszeniom lub gdy naruszenia mają charakter powtarzalny
              oraz uporczywy i to pomimo zastosowanego już wcześniej przez Usługodawcę zawieszenia Konta.
            </li>
            <li className="textTermsP">Niezwłocznie po podjęciu decyzji o zawieszeniu Konta, Usługodawca zawiadamia Użytkownika o przyczynie
              zawieszenia oraz wskazuje okres, na jaki Konto zostało zawieszone.</li>
            <li className="textTermsP">. W przypadku gdy zawieszenie Konta Użytkownika trwa co najmniej 30 dni kalendarzowych i przyczyny jego
              zawieszenia nadal nie ustały, Usługodawca ma prawo wypowiedzieć Użytkownikowi umowę o korzystanie
              z Konta z 7-dniowym terminem wypowiedzenia. Po upływie terminu wypowiedzenia Konto zostaje
              bezpowrotnie usunięte.</li>
            <li className="textTermsP">Usunięcie Konta Użytkownika w jakimkolwiek trybie pozostaje bez uszczerbku dla możliwości dalszego
              przechowywania przez Usługodawcę danych Użytkownika przez okres niezbędny dla realizacji pozostałych
              celów przetwarzania (innych niż prowadzenie Konta) zgodnie z polityką prywatności Aplikacji.</li>
            <li className="textTermsP">Po usunięciu Konta Użytkownik może w przyszłości założyć ponownie Konto, z zastrzeżeniem przypadków,
              gdy Konto zostało wypowiedziane przez Usługodawcę z przyczyn leżących po stronie Użytkownika zgodnie
              z postanowieniami pkt. 5.7 – 5.11 Regulaminu. W takim wypadku ponowne założenie Konta jest uzależnione
              od uprzedniej wyraźnej zgody Usługodawcy.</li>
          </ol>
          <h2 className="textTermsTitle">6) WARUNKI PŁATNOŚCI W APLIKACJI MEETZ</h2>
          <ol>
            <li className="textTermsP">Aplikacja Meetz może przewidywać odpłatny dostęp do niektórych funkcjonalności i zasobów. Dodatkowo,
              niektóre funkcjonalności Aplikacji mogą być objęte standardowo limitami, których wyłączenie lub
              podniesienie wymaga dokonania określonej płatności.</li>
            <li className="textTermsP">Użytkownik ma możliwość skorzystania z odpłatnych funkcjonalności i zasobów Aplikacji w drodze
              jednorazowych mikropłatności lub poprzez aktywację Wersji Premium.</li>
            <li className="textTermsP">Aktywacja Wersji Premium może nastąpić na dwa sposoby:
              <ol type="a">
                <li className="textTermsP"><span className="spanBold">Subskrypcja</span> – usługi Wersji Premium świadczone są w sposób ciągły w zamian za cyklicznie uiszczaną
                  przez Użytkownika opłatę. Użytkownik może zrezygnować z subskrybowanego planu subskrypcji lub
                  zmienić go na inny plan w każdej chwili i bez podania przyczyny – rezygnacja lub zmiana ta staje się
                  skuteczna z chwilą zakończenia trwającego okresu rozliczeniowego. Jeśli Użytkownik nie zrezygnuje
                  z subskrypcji najpóźniej ostatniego dnia okresu rozliczeniowego, subskrypcja ulega automatycznemu
                  przedłużeniu na kolejny okres zgodnie z dotychczasowym planem. Okres rozliczeniowy dla
                  subskrypcji Konta Premium wynosi 1 miesiąc (począwszy od daty aktywacji subskrypcji), chyba że w
                  opisie danego planu wskazano inny okres.
                </li>
                <li className="textTermsP"><span className="spanBold">Płatność jednorazowa</span> – Wersja Premium może zostać opłacona jednorazowo na określony z góry
                  okres, który nie odnawia się automatycznie. Po upływie czasu trwania, na jaki została zakupiona,
                  Wersja Premium wygasa. Użytkownik może ponowić jej zakup ręcznie w taki sam sposób jak za
                  pierwszym razem.</li>
              </ol>
            </li>
            <li className="textTermsP">Szczegółowe informacje o odpłatnych funkcjonalnościach Aplikacji dostępne są w trakcie korzystania z
              Aplikacji Meetz, w tym również w Cenniku. Usługodawca zastrzega sobie prawo dokonywania zmian w
              Cenniku, z zastrzeżeniem, że zmiany te nie będą w żaden sposób naruszać praw nabytych Użytkowników,
              w szczególności zmiany te nie będą wpływać na już zamówione, realizowane lub wykonane usługi.</li>
            <li className="textTermsP">Ceny uwidocznione w Aplikacji, w tym Cenniku, podane są w walucie zależnej od lokalizacji Użytkownika (tj.
              zgodnie z walutą wskazaną każdorazowo obok danej ceny) i zawierają podatki. O łącznej cenie wraz z
              podatkami oraz o innych kosztach, a gdy nie można ustalić wysokości tych opłat – o obowiązku ich
              uiszczenia, Użytkownik jest informowany w Aplikacji, w tym także w trakcie składania zamówienia i
              dokonywania płatności za wybraną usługę. W razie umowy zawieranej na czas nieoznaczony (np.
              subskrypcja), Usługodawca podaje w ten sam sposób łączną cenę lub wynagrodzenie obejmujące wszystkie
              płatności za okres rozliczeniowy, a gdy umowa przewiduje stałą stawkę – także łączne miesięczne płatności
              z tytułu tej umowy.</li>
            <li className="textTermsP">Aktywacja odpłatnych funkcjonalności możliwa jest po zalogowaniu do Konta Użytkownika. Aby dokonać
              zakupu, należy wybrać interesującą Użytkownika usługę (np. plan subskrypcji) oraz przejść do realizacji
              płatności. Po przekierowaniu do bramki płatności, Użytkownik powinien podać dane wymagane do celów
              rozliczeniowych oraz niezwłocznie dokonać zapłaty za usługę. W przeciwnym razie sesja płatności może
              wygasnąć i transakcja nie dojdzie do skutku, a wybrana usługa nie zostanie aktywowana – Użytkownik może
              wtedy wznowić zakup w taki sam sposób jak za pierwszym razem.</li>
            <li className="textTermsP">Usługodawca udostępnia Użytkownikom następujące sposoby płatności:
              <ol type="a">
                <li className="textTermsP">Płatności elektroniczne lub kartą płatniczą za pośrednictwem serwisu Stripe.com. Obsługą płatności
                  w Aplikacji Meetz zajmuje się spółka spółka Stripe Payments Europe, Ltd. (1 Grand Canal Street
                  Lower, Grand Canal Dock, Dublin, Irlandia).</li>
                <li className="textTermsP">Płatność Kredytami, które doładowuje się za pomocą Aplikacji i przypisane są na stałe do salda na
                  Koncie Użytkownika. Kredyty wykorzystane na zakup danej funkcjonalności zostają automatycznie
                  odjęte od salda na Koncie. Kredyty są niezbywalne, nie mogą być przenoszone między
                  Użytkownikami ani wykorzystywane poza Aplikacją, nie podlegają także wypłacie w innej walucie ani
                  waloryzacji.</li>
              </ol>
            </li>
            <li className="textTermsP">W przypadku aktywacji subskrypcji Konta Premium płatności realizowane są automatycznie poprzez
              obciążenie karty płatniczej Użytkownika na początku każdego okresu rozliczeniowego. W celu zachowania
              ciągłości subskrypcji Konta Premium, Użytkownik powinien zapewnić pozytywną autoryzację karty
              płatniczej. W przeciwnym razie płatność nie będzie mogła zostać zrealizowana i subskrypcja zostanie
              zawieszona do czasu jej ponownej aktywacji przez Użytkownika, co oznaczać będzie także wygaśnięcie
              Konta Premium.</li>
            <li className="textTermsP">. Wszelkie odpłatne funkcjonalności Aplikacji, w tym subskrypcje, są aktywowane na Koncie Użytkownika
              niezwłocznie, lecz nie później niż w ciągu kolejnych 72 godzin od chwili dokonania wymaganej płatności
              przez Użytkownika. Płatność uważa się za dokonaną z chwilą uznania rachunku bankowego lub
              rozliczeniowego Usługodawcy.</li>
            <li className="textTermsP">Rezygnacja z odpłatnych Usług Elektronicznych, w tym Konta Premium, po ich wykonaniu lub aktywowaniu
              nie uprawnia Użytkownika do otrzymania zwrotu płatności, z zastrzeżeniem, że nie wyłącza ani nie ogranicza
              to jakichkolwiek uprawnień ustawowych Użytkownika będącego konsumentem, szczególnie w zakresie
              prawa odstąpienia od umowy zawartej na odległość lub reklamacji nienależycie spełnionego świadczenia.</li>
            <li className="textTermsP">. Utrwalenie, zabezpieczenie oraz udostępnienie Użytkownikowi informacji o zakupie dokonanym w Aplikacji
              następuje poprzez (1) udostępnienie niniejszego Regulaminu w Aplikacji przed dokonaniem zakupu, (2)
              przesłanie Użytkownikowi wiadomości e-mail z potwierdzeniem zakupu niezwłocznie po zrealizowaniu
              płatności oraz (3) odnotowanie płatności w historii zakupów dostępnej z poziomu Konta Użytkownika.
            </li>
          </ol>
          <h2 className="textTermsTitle">7) TREŚCI UŻYTKOWNIKA</h2>
          <ol>
            <li className="textTermsP">Jednym z podstawowych założeń Aplikacji jest możliwość dzielenia się przez Użytkowników swoimi
              treściami, do których należą wszelkie formy zawartości udostępnianej przez Użytkownika w zamiarze, aby
              stała się ona następnie widoczna dla innego Użytkownika lub Użytkowników korzystających z Aplikacji.
              Treści, o których mowa w zdaniu poprzednim, obejmują w szczególności posty, komentarze, reakcje, oceny,
              opinie, w tym zdjęcia i inne materiały wizualne, audio lub wideo, które mogą być udostępniane,
              wprowadzane, przesyłane, rozpowszechniane lub transmitowane przy użyciu odpowiednich
              funkcjonalności Aplikacji, w tym za pośrednictwem profilu lub tablicy Użytkownika.</li>
            <li className="textTermsP">Użytkownik ponosi pełną odpowiedzialność za wszystkie transmitowane, przechowywane, udostępniane,
              rozpowszechniane i publikowane przez siebie w ramach Aplikacji treści.
            </li>
            <li className="textTermsP">Użytkownik umieszczając lub przesyłając jakiekolwiek treści w ramach Aplikacji obowiązany jest posiadać
              wszelkie wymagane prawa i zezwolenia do umieszczania takich treści w Aplikacji, w szczególności prawa
              autorskie lub wymagane licencje, zezwolenia i zgody na ich wykorzystywanie, rozpowszechnianie,
              udostępnianie, publikację itd., zwłaszcza prawo publikowania i rozpowszechniania w sieci Internet, systemie
              on-line oraz prawo do wykorzystania i rozpowszechniania wizerunku w przypadku treści, które obejmują
              wizerunek osób trzecich.</li>
            <li className="textTermsP">Wszelkie wypowiedzi, oceny, opinie, komentarze i reakcje zamieszczane przez Użytkowników w Aplikacji
              zawierają ich własne opinie i nie stanowią opinii Usługodawcy</li>
            <li className="textTermsP">Treści Użytkowników nie mogą zawierać wulgaryzmów, treści obraźliwych, nawołujących do szerzenia
              nienawiści, rasizmu lub ksenofobii, a także treści o charakterze reklamowym, ogłoszeniowym lub informacji
              handlowych zachęcających do korzystania ze stron i portali konkurencyjnych w stosunku do Aplikacji Meetz,
              w tym także adresów stron internetowych, nazw oraz logo takich serwisów.</li>
            <li className="textTermsP">Z chwilą zamieszczenia przez Użytkownika w Aplikacji jakichkolwiek treści, do których prawa autorskie lub
              inne wymagane prawem zezwolenia przysługują temu Użytkownikowi, udzielona zostaje Usługodawcy
              nieodpłatna, niewyłączna, zbywalna i przenoszalna licencja na korzystanie z otrzymanych treści (w tym
              również ich sublicencjonowanie), obejmująca prawo do korzystania z autorskich praw majątkowych, bez
              ograniczeń co do terytorium i czasu (a w każdym razie nie krócej niż przez czas trwania umowy o korzystanie
              z Aplikacji przez Użytkownika), na następujących polach eksploatacji: (1) trwałego lub czasowego
              rozpowszechniania, wyświetlania, stosowania, odtwarzania, publicznego udostępniania oraz
              przechowywania tych treści techniką cyfrową, w tym za pośrednictwem komputera, urządzeń mobilnych
              oraz sieci Internet, dla zapewnienia prawidłowego działania Aplikacji, w zakresie świadczenia Usług
              Elektronicznych na rzecz wszystkich Użytkowników Aplikacji, w tym aby zapewnić im dostęp do tych treści
              §
              Strona 8 z 23
              w miejscu i czasie przez nich wybranym, z uwzględnieniem zamierzonego przeznaczenia i zakresu odbiorców
              wynikającego ze sposobu udostępnienia treści przez danego Użytkownika; (2) możliwości prowadzenia
              promocji i reklamy Aplikacji w innych mediach elektronicznych, w tym poprzez publiczne udostępnianie
              treści za pośrednictwem profili Usługodawcy, grup dyskusyjnych, stron i fanpage’y powiązanych z Aplikacją
              w mediach społecznościowych, a także na innych blogach, portalach, stronach internetowych i aplikacjach
              (z wyłączeniem treści, które udostępnione zostały przez Użytkownika w ramach prywatnych rozmów na
              Czacie prowadzonych z innymi Użytkownikami); (3) trwałego lub czasowego zwielokrotnienia tych treści w
              całości lub w części, techniką cyfrową, w zakresie, w którym dla rozpowszechniania, wyświetlania,
              stosowania, odtwarzania, publicznego udostępniania oraz ich przechowywania w wyżej przewidzianych
              celach i zakresie niezbędne jest ich zwielokrotnienie. Nadto, Użytkownik zobowiązuje się nie wykonywać w
              stosunku do Usługodawcy autorskich praw osobistych do wymienionych wyżej treści oraz zezwala
              Usługodawcy na dokonywanie ich opracowań (prawa zależne) oraz ich dalsze wykorzystanie na
              wymienionych polach eksploatacji bez oznaczenia autorstwa (imienia i nazwiska, nazwy itp.) Użytkownika.</li>
            <li className="textTermsP">. W przypadku skierowania jakichkolwiek roszczeń przeciwko Usługodawcy, których podstawę będzie
              stanowiło bezprawne wykorzystanie, w tym rozpowszechnianie treści przez Użytkownika w ramach Aplikacji
              Meetz, ze strony osób trzecich, w tym organów administracji publicznej, z tytułu naruszenia ich praw
              autorskich lub z tytułu naruszenia przepisów o ochronie danych osobowych lub innych przepisów
              powszechnie obowiązującego prawa, Użytkownik zobowiązuje się do zwolnienia Usługodawcy z
              odpowiedzialności za dokonane przez tego Użytkownika naruszenia, zaspokojenia roszczeń ww. osób
              trzecich oraz kar lub innych kosztów nałożonych przez właściwe organy administracji publicznej na
              Usługodawcę oraz do zwolnienia Usługodawcy z obowiązku świadczenia z tego tytułu oraz naprawienia na
              zasadach ogólnych szkody poniesionej przez Usługodawcę z powodu naruszeń Użytkownika.
            </li>
            <li className="textTermsP">Niezależnie od innych prawem przewidzianych środków oraz bez uszczerbku dla pozostałych postanowień
              niniejszego Regulaminu, Usługodawca zastrzega sobie prawo usunięcia z Aplikacji treści naruszających
              powyższe postanowienia punktu 8. Regulaminu.</li>
            <li className="textTermsP"> Zgodnie z art. 15 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (t.j. Dz.U. z 2020 r.
              poz. 344) Usługodawca nie jest obowiązany do sprawdzania przekazywanych, przechowywanych lub
              udostępnianych za pośrednictwem Aplikacji danych Użytkowników. Nadto, zgodnie z art. 14 ustawy
              Usługodawca nie ponosi odpowiedzialności, jeśli nie wie o bezprawnym charakterze danych lub związanej
              z nimi działalności, a w razie otrzymania urzędowego zawiadomienia lub uzyskania wiarygodnej wiadomości
              o bezprawnym charakterze danych, podejmuje odpowiednie kroki w celu niezwłocznego uniemożliwienia
              dostępu do tych danych.</li>
          </ol>
          <h2 className="textTermsTitle">8) KORZYSTANIE Z CZATU</h2>
          <ol>
            <li className="textTermsP">Usługa Elektroniczna Czat umożliwia Użytkownikom komunikację na odległość przy wykorzystaniu
              funkcjonalności dostępnych dla wybranego Czatu. Rozmowę na Czacie, w zależności od wyboru, można
              prowadzić z Użytkownikami z listy znajomych, jak również z losowo wybranymi osobami spośród
              Użytkowników Aplikacji.
            <ol type="a">
              <li className="textTermsP"><span className="spanBold">Czat Tekstowy </span>– Użytkownicy mogą wysyłać do siebie wiadomości tekstowe lub głosowe. Do
                prawidłowego korzystania z wiadomości głosowych, niezbędne jest posiadanie przez Użytkownika
                sprawnego mikrofonu oraz głośników lub słuchawek w celu odtwarzania otrzymanych wiadomości
                głosowych.</li>
              <li className="textTermsP"><span className="spanBold">Czat Wideo </span> – Użytkownicy mogą prowadzić ze sobą wideorozmowę. Do prawidłowego przebiegu
                wideorozmowy, niezbędne jest posiadanie przez Użytkownika sprawnej kamerki internetowej,
                mikrofonu oraz głośników lub słuchawek w celu słyszenia rozmówcy. Jakość wideorozmowy może
                zależeć także od prędkości łącza internetowego Użytkownika.</li>
            </ol>
            </li>
            <li className="textTermsP">Usługa Elektroniczna Czat świadczona jest w Aplikacji przez czas nieokreślony. Użytkownik może w każdej
              chwili i bez podania przyczyny zakończyć korzystanie z Czatu, w tym poprzez opuszczenie Aplikacji lub
              zamknięcie okienka Czatu.</li>
            <li className="textTermsP"> Podstawą korzystania z Czatu są treści przesyłane, przekazywane lub transmitowane przez Użytkownika,
              które stają się następnie widoczne dla rozmówcy, z którym ten Użytkownik komunikuje się na Czacie.
              Użytkownik dobrowolnie i we własnym zakresie decyduje o tym, jakie informacje i dane podaje na Czacie.</li>
            <li className="textTermsP">. Rola Usługodawcy Aplikacji polega wyłącznie na pośredniczeniu w transmisji i udostępnianiu danych
              Użytkowników korzystających z Czatu. Usługodawca 1) nie jest inicjatorem przekazu danych, 2) nie wybiera
              odbiorcy przekazu danych oraz 3) nie wybiera i nie modyfikuje informacji zawartych w tym przekazie. Nadto,
              w zakresie Czatu Wideo Usługodawca może dokonywać automatycznego i krótkotrwałego pośredniego
              przechowywania transmitowanych danych wyłącznie w celu przeprowadzenia transmisji, przy czym dane
              nie będą przechowywane dłużej, niż jest to w zwykłych warunkach konieczne dla realizacji transmisji. Zapis
              rozmowy z Czatu Tekstowego dostępny jest po zakończeniu rozmowy w historii Czatu z poziomu Konta
              Użytkownika.</li>
            <li className="textTermsP">Każdy Użytkownik ponosi pełną odpowiedzialność prawną za wszystkie transmitowane, przechowywane,
              udostępniane, rozpowszechniane i publikowane przez siebie w ramach Czatu treści i dane.</li>
            <li className="textTermsP">Umieszczając, transmitując lub przesyłając jakiekolwiek treści i dane w ramach Czatu Użytkownik
              obowiązany jest posiadać wszelkie wymagane prawa i zezwolenia do umieszczania takich treści i danych na
              Czacie, w szczególności prawa autorskie lub wymagane licencje, zezwolenia i zgody na ich wykorzystywanie,
              rozpowszechnianie, udostępnianie, publikację itd., zwłaszcza prawo publikowania i rozpowszechniania w
              sieci Internet, systemie on-line oraz prawo do wykorzystania i rozpowszechniania wizerunku w przypadku
              treści, które obejmują wizerunek osób trzecich.</li>
            <li className="textTermsP">Użytkownika obowiązuje bezwzględny zakaz korzystania z Czatu w sposób, który może stanowić czyny
              zabronione i karalne.</li>
            <li className="textTermsP">Użytkownikowi zabrania się przesyłania za pośrednictwem Czatu jakichkolwiek treści, których celem lub
              skutkiem jest wyrządzenie szkód po stronie rozmówcy (w szczególności złośliwego oprogramowania
              komputerowego typu „wirusy”, „robaki” czy „konie trojańskie”) lub udostępnianie danych zawierających
              lub umożliwiających dostęp do bezprawnych treści warezowych (np. nielegalnie pozyskanych plików
              komputerowych) lub pornograficznych.
            </li>
            <li className="textTermsP">Użytkownikowi zabrania się korzystania z Czatu w sposób uciążliwy dla innych Użytkowników Aplikacji.</li>
            <li className="textTermsP">. Zabronione jest wysyłanie wypowiedzi obrażających innych Użytkowników i naruszających ich dobra
              osobiste. Użytkownikowi zabrania się wykorzystywania Czatu do przesyłania niezamówionych informacji
              handlowych (spamu), nękania i niepokojenia innych Użytkowników, wyłudzania danych oraz przesyłania
              treści bezprawnych, obraźliwych, treści nawołujących do szerzenia nienawiści, rasizmu lub ksenofobii bądź
              innych treści sprzecznych z dobrze pojętymi regułami netykiety oraz dobrymi obyczajami.
            </li>
            <li className="textTermsP">Użytkownik powinien zadbać o zrozumiałą, niewprowadzającą rozmówców w błąd formę swoich
              wypowiedzi. Użytkownik powinien odnosić się do swoich rozmówców z szacunkiem.</li>
            <li className="textTermsP">Użytkownik prowadząc na Czacie rozmowy z obcymi, przypadkowymi osobami powinien pamiętać, że
              wysyłane przez jego rozmówców wiadomości i komunikaty nie są treściami zweryfikowanymi i mogą
              zawierać informacje błędne, nierzetelne, fikcyjne itd. Usługodawca przestrzega przed ryzykiem związanym
              z wchodzeniem w interakcje z obcymi osobami, w szczególności ze względu na brak pewności co tożsamości
              danej osoby ani wiarygodności jej wypowiedzi. Każdy Użytkownik powinien zawsze zachować ostrożność i
              kierować się rozsądkiem w kontaktach z przypadkowymi osobami, a w szczególności nie podawać na Czacie
              żadnych danych, które uważa za poufne.</li>
            <li className="textTermsP">Zabronione w każdym wypadku jest publikowanie, transmitowanie lub w jakikolwiek sposób udostępnianie
              w ramach Czatu, w tym podczas wideorozmów, treści i materiałów: (1) z pornografią dziecięcą, z
              uczestnictwem osób niepełnoletnich, zdjęć/filmów zawierających pornografię dziecięcą lub zdjęć/filmów
              nagich dzieci, w tym także animacje i tzw. cartoon obejmujący seks z nieletnimi lub okazywanie nieletnich,
              (2) z pornografią z udziałem zwierząt, z uczestnictwem w aktach seksualnych z udziałem zwierząt,
              zdjęć/filmów obejmujących pornografię zwierzęcą, (2) obejmujących pokaz, próbę lub imitację
              samobójstwa, nakłaniających Użytkowników do popełnienia aktu samobójstwa, jak też obejmujących
              pokaz, próbę lub imitację okaleczenia lub samookaleczenia lub nakłaniających Użytkowników do dokonania
              okaleczenia albo samookaleczenia, (3) obejmujących nekrofilię (akty seksualne, prawdziwe lub sfingowane,
              z udziałem zwłok ludzkich lub zwierząt), (4) z udziałem osób nieprzytomnych lub sprawiających wrażenie
              nieprzytomnych, odurzonych alkoholem, narkotykami lub innymi środkami psychoaktywnymi, albo z
              udziałem osób, które pomimo przytomności fizycznej są lub wydają się nieświadome sytuacji w jakiej się
              znajdują, w szczególności obecności przed kamerą internetową, (5) obejmujących zgwałcenie lub symulację
              albo imitację zgwałcenia, (6) demonstrujących lub prezentujących nielegalne użycie broni, narkotyków,
              alkoholu, innych środków odurzających, jak też nakłaniających do tych czynności, (7)
              demonstrujących/prezentujących przemoc wobec ludzi i zwierząt lub nawołujących do takiej przemocy, (8)
              demonstrujących/prezentujących kazirodztwo, (9) obejmujących antysemickie, rasistowskie obelgi,
              propagujących treści lub zachowania nazistowskie i/lub faszystowskie lub nawołujących do takich
              zachowań, (10) obejmujących informacje o charakterze instruktażowym stanowiące działania sprzeczne z
              prawem lub nawołujące do działań sprzecznych z prawem, jak konstruowanie ładunków wybuchowych,
              używanie broni palnej, kreowanie wirusów komputerowych i ich rozsyłanie, (11) polegających na
              atakowaniu (nie fizycznym) innej osoby, jej prywatności, (12) ukazujących treści chronione prawem
              własności intelektualnej, w tym prawem autorskim, w zakresie nieobjętym instytucją dozwolonego użytku,
              do których rozpowszechniania Użytkownicy nie posiadają wymaganych prawnie zezwoleń, licencji i zgód. </li>
            <li className="textTermsP"> Użytkownik nie może korzystać z Czatu w celu oferowania usług z zakresu prostytucji, umawiania się z
              Użytkownikami w celu świadczenia usług z zakresu prostytucji lub za pośrednictwem Czatu zlecać takich
              usług.
            </li>
          </ol>
          <h2 className="textTermsTitle">9) FUNKCJONALNOŚCI AI – WIRTUALNY PRZYJACIEL ORAZ PODPOWIEDZI CZATU</h2>
          <ol>
            <li className="textTermsP">Aplikacja Meetz zawiera także funkcjonalności opierające się na oprogramowaniu sztucznej inteligencji
              udostępnianym przez firmę trzecią OpenAI OpCo, LLC (3180 18th Street, San Francisco, California, USA).
              OpenAI jest samodzielnym podmiotem działającym niezależnie od Usługodawcy Aplikacji Meetz.
            </li>
            <li className="textTermsP">Do funkcjonalności, o których mowa w pkt. 9.1 Regulaminu, należą:
            <ol type="a">
              <li className="textTermsP">
                Usługa Elektroniczna Wirtualny Przyjaciel – jest to interaktywny chatbot symulujący rozmowę z
                człowiekiem. Usługa pozwala prowadzić rozmowę z Wirtualnym Przyjacielem poprzez wybór
                jednego z gotowych modeli przygotowanych przez Usługodawcę Aplikacji (każdy model posiada
                określone z góry cechy determinujące sposób jego zachowania, stan wiedzy czy styl wypowiedzi,
                zgodnie z opisem podanym obok wybranego modelu) albo też z modelem skonfigurowanym
                samodzielnie przez Użytkownika za pomocą dostępnych w Aplikacji ustawień. Rozmowa z
                Wirtualnym Przyjacielem przypomina korzystanie z Czatu – na podstawie przesłanych przez
                Użytkownika zapytań Wirtualny Przyjaciel generuje odpowiedzi w sposób zautomatyzowany, tj. bez
                weryfikacji i moderacji ich treści ze strony człowieka. Założeniem Usługi Elektronicznej Wirtualny
                Przyjaciel jest imitowanie wrażeń podobnych do rozmowy prowadzonej człowiekiem, co przejawia
                się w szczególności generowaniem treści stwarzających pozory ich autentyczności, co nie może być
                jednak równoznaczne z tym, iż są one wolne od jakichkolwiek błędów i nieścisłości.
              </li>
              <li className="textTermsP">
                Podpowiedzi Czatu – jest to funkcjonalność Usługi Elektronicznej Czat, która umożliwia wyświetlanie
                podpowiedzi generowanych na podstawie analizy historii dotychczasowej rozmowy prowadzonej
                przez Użytkownika na Czacie. Rolą podpowiedzi na Czacie jest jedynie sugerowanie Użytkownikowi
                przykładowej treści jego odpowiedzi na kolejne pytanie rozmówcy lub też sposobu, w jaki może
                potencjalnie pokierować rozmową. Podpowiedzi są generowane w sposób zautomatyzowany, tj. bez
                weryfikacji i moderacji człowieka. Podpowiedzi są niewiążące i korzystanie z nich jest dobrowolną
                decyzją każdego Użytkownika.
              </li>
            </ol>
            </li>
            <li className="textTermsP">Użytkownik podczas korzystania z funkcjonalności AI obowiązany jest do przestrzegania zasad użytkowania
              oprogramowania sztucznej inteligencji firmy OpenAI dostępnych pod adresem internetowym: <a href="https://openai.com/policies/usage-policies">https://openai.com/policies/usage-policies</a>. </li>
            <li className="textTermsP">Niniejszy Regulamin nie ma na celu wyłączać ani ograniczać zasad użytkowania oprogramowania sztucznej
              inteligencji udostępnianych przez OpenAI. Niniejszy Regulamin dotyczy wyłącznie warunków korzystania z
              Usług Elektronicznych Aplikacji oraz odpowiedzialności Usługodawcy i pozostaje bez wpływu na
              odpowiedzialność OpenAI za działanie rozwijanego przez nią oprogramowania. W przypadku jakiejkolwiek
              sprzeczności między postanowieniami niniejszego Regulaminu a zasadami użytkowania modelu sztucznej
              inteligencji udostępnianymi przez OpenAI należy stosować te zasady użytkowania.</li>
            <li className="textTermsP">Treści, które generuje sztuczna inteligencja w Aplikacji Meetz, nie powinny stanowić wyłącznej podstawy
              jakiejkolwiek decyzji podejmowanej przez Użytkownika ani też nie mogą być wiążącym źródłem wiedzy na
              jakiekolwiek interesujące Użytkownika tematy. Funkcjonalności AI dostępne w Aplikacji mają zastosowanie
              wyłącznie informacyjne i rozrywkowe. Usługodawca przestrzega, że treści generowane przez sztuczną
              inteligencję nie są weryfikowane ani moderowane przez człowieka, a stan wiedzy, na jakim opiera się model
              sztucznej inteligencji, jest ograniczony. W konsekwencji nie jest możliwe zapewnienie, aby informacje, w
              tym podpowiedzi, generowane przez sztuczną inteligencję były wolne od błędów i nieścisłości.</li>
            <li className="textTermsP">Usługodawca nie czyni żadnych dorozumianych lub wyrażonych otwarcie zapewnień co do tego, iż treści
              generowane w trakcie korzystania z funkcjonalności AI okażą się przydatne lub pomocne do osiągnięcia
              rezultatów oczekiwanych przez Użytkownika. Zastrzeżenie, o którym mowa w zdaniu poprzednim, nie ma
              jednak na celu wyłączać ani ograniczać jakichkolwiek praw ustawowych Użytkownika będącego
              konsumentem, szczególnie w zakresie ewentualnej odpowiedzialności Usługodawcy za nienależyte
              spełnienie świadczenia.
            </li>
          </ol>
          <h2 className="textTermsTitle">10) KONTAKT Z USŁUGODAWCĄ</h2>
          <div className="textTermsP">Zasadniczą formą bieżącej komunikacji na odległość z Usługodawcą jest formularz kontaktowy dostępny po
            zalogowaniu do Aplikacji oraz poczta elektroniczna (adres e-mail:  <a href="mailto:support@meetz.life">support@meetz.life</a>), za pośrednictwem
            których można wymieniać z Usługodawcą informacje dotyczące korzystania z Aplikacji Meetz. Użytkownicy mogą
            kontaktować się z Usługodawcą także na inne dopuszczalne prawem sposoby, korzystając z danych kontaktowych
            zamieszczonych na wstępie Regulaminu.
          </div>
          <h2 className="textTermsTitle">11) PROCEDURA ROZPATRYWANIA REKLAMACJI</h2>
          <ol>
            <li className="textTermsP">Niniejszy punkt 11. Regulaminu określa procedurę rozpatrywania reklamacji wspólną dla wszystkich
              reklamacji składanych do Usługodawcy, w szczególności reklamacji dotyczących Usług Elektronicznych oraz
              pozostałych reklamacji związanych z działaniem Usługodawcy lub Aplikacji Meetz.
            </li>
            <li className="textTermsP">Poniżej wskazane są dodatkowe informacje dotyczące przewidzianej przez prawo odpowiedzialności
              Usługodawcy:
              <ol type="a">
                <li className="textTermsP">Podstawa i zakres odpowiedzialności Usługodawcy wobec Użytkownika za działanie Aplikacji i jej
                  Usług Elektronicznych, określone są przez powszechnie obowiązujące przepisy prawa polskiego, w
                  szczególności w Kodeksie Cywilnym, Ustawie o Prawach Konsumenta oraz ustawie z dnia 18 lipca
                  2002 r. o świadczeniu usług drogą elektroniczną ((t.j. Dz.U. z 2020 r. poz. 344).</li>
                <li className="textTermsP">W przypadku treści cyfrowych lub usług cyfrowych nabytych przez Użytkownika będącego
                  konsumentem, zastosowanie mają przepisy Ustawy o Prawach Konsumenta w brzmieniu
                  obowiązującym od dnia 1. stycznia 2023 r., w szczególności art. 43h – 43q Ustawy o Prawach
                  Konsumenta. Przepisy te określają w szczególności podstawę i zakres odpowiedzialności
                  Usługodawcy względem konsumenta w razie braku zgodności treści cyfrowej lub usługi cyfrowej z
                  zawartą umową.
                </li>
              </ol>
            </li>
            <li className="textTermsP">Reklamacje związane z działaniem Aplikacji, w tym jej Usług Elektronicznych, można składać na przykład
              drogą poczty elektronicznej (mailowo) na adres:  <a href="mailto:support@meetz.life">support@meetz.life</a> lub pisemnie na adres: ul. Juliusza
              Słowackiego 55/1, 60-521 Poznań</li>
            <li className="textTermsP">Usługodawca zaleca podanie w opisie reklamacji: (1) informacji i okoliczności dotyczących przedmiotu
              reklamacji, w szczególności rodzaju i daty wystąpienia nieprawidłowości; (2) oczekiwań osoby składającej
              reklamację; oraz (3) danych kontaktowych składającego reklamację – ułatwi to i przyspieszy rozpatrzenie
              reklamacji przez Usługodawcę. Wymogi podane w zdaniu poprzednim mają formę jedynie zalecenia i nie
              wpływają na skuteczność reklamacji złożonych z pominięciem zalecanego opisu reklamacji.</li>
            <li className="textTermsP">W razie zmiany podanych danych kontaktowych przez składającego reklamację w trakcie rozpatrywania
              reklamacji zobowiązany jest on do powiadomienia o tym Usługodawcy.
            </li>
            <li className="textTermsP">Do reklamacji mogą zostać załączone przez składającego reklamację dowody (np. zdjęcia, dokumenty lub
              zrzuty ekranu) związane z przedmiotem reklamacji. Usługodawca może także zwrócić się do składającego
              §
              Strona 12 z 23
              reklamację z prośbą o podanie dodatkowych informacji lub przesłanie dowodów (np. zdjęcia), jeżeli ułatwi
              to i przyspieszy rozpatrzenie reklamacji przez Usługodawcę. </li>
            <li className="textTermsP">Usługodawca ustosunkuje się do reklamacji niezwłocznie, nie później niż w terminie 14 dni kalendarzowych
              od dnia jej otrzymania.</li>
          </ol>
          <h2 className="textTermsTitle">12) USTAWOWE PRAWO ODSTĄPIENIA OD UMOWY</h2>
          <ol>
            <li className="textTermsP">Konsument, który zawarł umowę na odległość, może w terminie 14 dni kalendarzowych odstąpić od niej
              bez podawania przyczyny i bez ponoszenia kosztów, z zastrzeżeniem przypadków wskazanych w pkt. 12.4
              oraz kosztów określonych w pkt. 12.5 Regulaminu. </li>
            <li className="textTermsP">Bieg terminu do odstąpienia od umowy, której przedmiotem jest korzystanie z Aplikacji oraz jej Usług
              Elektronicznych, rozpoczyna się od dnia zawarcia umowy.</li>
            <li className="textTermsP"> Do zachowania terminu na odstąpienie od umowy wystarczy wysłanie oświadczenia przed jego upływem.
              Oświadczenie o odstąpieniu od umowy może zostać złożone na przykład w formie elektronicznej za
              pośrednictwem poczty elektronicznej na adres:  <a href="mailto:support@meetz.life">support@meetz.life</a> lub pisemnie na adres: ul. Juliusza
              Słowackiego 55/1, 60-521 Poznań. Konsument może w tym celu skorzystać ze wzoru oświadczenia o
              odstąpieniu od umowy, który stanowi załącznik nr 2 do Ustawy o Prawach Konsumenta, jednak nie jest to
              obowiązkowe.
            </li>
            <li className="textTermsP">Prawo odstąpienia od umowy zawartej na odległość nie przysługuje konsumentowi m. in. w odniesieniu do
              umów: (1) o świadczenie usług, za które konsument jest zobowiązany do zapłaty ceny, jeżeli Usługodawca
              wykonał w pełni usługę za wyraźną i uprzednią zgodą konsumenta, który został poinformowany przed
              rozpoczęciem świadczenia, że po spełnieniu świadczenia przez Usługodawcę utraci prawo odstąpienia od
              umowy, i przyjął to do wiadomości; (2) o dostarczanie treści cyfrowych, które nie są zapisane na nośniku
              materialnym, za które konsument jest zobowiązany do zapłaty ceny, jeżeli Usługodawca rozpoczął
              świadczenie za wyraźną i uprzednią zgodą konsumenta, który został poinformowany przed rozpoczęciem
              świadczenia, że po spełnieniu świadczenia przez Usługodawcę utraci prawo odstąpienia od umowy, i przyjął
              to do wiadomości, a Usługodawca przekazał konsumentowi potwierdzenie, o którym mowa w art. 15 ust. 1
              i 2 albo art. 21 ust. 1 Ustawy o Prawach Konsumenta.</li>
            <li className="textTermsP"><span className="spanBold">Skutki i koszty związane z wykonaniem prawa odstąpienia od umowy:</span>
            <ol type="a">
              <li className="textTermsP">W przypadku odstąpienia od umowy, której przedmiotem są treści cyfrowe lub usługi cyfrowe –
                Usługodawca od dnia otrzymania oświadczenia konsumenta o odstąpieniu od umowy nie może
                wykorzystywać treści innych niż dane osobowe dostarczone lub wytworzone przez konsumenta w
                trakcie korzystania z treści cyfrowych lub usługi cyfrowej – dostarczonych przez Usługodawcę, z
                wyjątkiem treści, które: (1) są użyteczne wyłącznie w związku z treścią cyfrową lub usługą cyfrową,
                które stanowiły przedmiot umowy; (2) dotyczą wyłącznie aktywności konsumenta w trakcie
                korzystania z treści cyfrowych lub usługi cyfrowej dostarczonych przez Usługodawcę; (3) zostały
                połączone przez Usługodawcę z innymi danymi i nie mogą zostać z nich wydzielone lub mogą zostać
                wydzielone jedynie przy nakładzie niewspółmiernych wysiłków; (4) zostały wytworzone przez
                konsumenta wspólnie z innymi konsumentami, którzy nadal mogą z nich korzystać. Z wyjątkiem
                przypadków, o których mowa wyżej w pkt (1)–(3), Usługodawca na żądanie konsumenta udostępnia
                mu treści inne niż dane osobowe, które zostały dostarczone lub wytworzone przez konsumenta w
                trakcie korzystania z treści cyfrowych lub usługi cyfrowej dostarczonych przez Usługodawcę. W
                przypadku odstąpienia od umowy Usługodawca może uniemożliwić konsumentowi dalsze
                korzystanie z treści cyfrowych lub usługi cyfrowej, w szczególności przez uniemożliwienie
                konsumentowi dostępu do treści cyfrowych lub usługi cyfrowej z poziomu Konta Użytkownika, co
                nie ma wpływu na uprawnienia konsumenta, o których mowa w zdaniu poprzednim. Konsument ma
                prawo odzyskać treści cyfrowe od Usługodawcy nieodpłatnie, bez przeszkód ze strony Usługodawcy,
                w rozsądnym terminie i powszechnie używanym formacie przeznaczonym do odczytu maszynowego.
              </li>
              <li className="textTermsP">W przypadku odstąpienia od umowy, której przedmiotem są treści cyfrowe lub usługi cyfrowe
                dostarczane przez Usługodawcę, konsument jest zobowiązany zaprzestać korzystania z treści
                cyfrowej lub usługi cyfrowej i udostępniania ich osobom trzecim.</li>
              <li className="textTermsP">W przypadku skutecznego odstąpienia od umowy, do której nie ma zastosowania żadne z wyłączeń
                wskazanych w punkcie 12.4 wyżej, Usługodawca ma obowiązek niezwłocznie, nie później niż w
                terminie 14 dni kalendarzowych od dnia otrzymania oświadczenia konsumenta o odstąpieniu od
                umowy, zwrócić konsumentowi wszystkie dokonane przez niego płatności. Usługodawca dokonuje
                zwrotu płatności przy użyciu takiego samego sposobu płatności, jakiego użył konsument, chyba że
                konsument wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi
                kosztami.</li>
              <li className="textTermsP">. W przypadku usługi, której wykonywanie – na wyraźne żądanie konsumenta – rozpoczęło się przed
                upływem terminu do odstąpienia od umowy, konsument, który wykonuje prawo odstąpienia od
                umowy po zgłoszeniu takiego żądania, ma obowiązek zapłaty za świadczenia spełnione do chwili
                odstąpienia od umowy. Kwotę zapłaty oblicza się proporcjonalnie do zakresu spełnionego
                świadczenia, z uwzględnieniem uzgodnionej w umowie ceny lub wynagrodzenia. Jeżeli cena lub
                wynagrodzenie są nadmierne, podstawą obliczenia tej kwoty jest wartość rynkowa spełnionego
                świadczenia.
              </li>
            </ol>
            </li>
          </ol>
          <h2 className="textTermsTitle">13) POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA
          ROSZCZEŃ ORAZ ZASADY DOSTĘPU DO TYCH PROCEDUR
          </h2>
          <ol>
            <li className="textTermsP">Metody rozwiązywania sporów bez udziału sądu to między innymi (1) umożliwienie zbliżenia stanowisk
              stron, np. poprzez mediację; (2) zaproponowanie rozwiązania sporu, np. poprzez koncyliację oraz (3)
              rozstrzygnięcie sporu i narzucenie stronom jego rozwiązania, np. w ramach arbitrażu (sąd polubowny).
              Szczegółowe informacje dotyczące możliwości skorzystania przez konsumenta z pozasądowych sposobów
              rozpatrywania reklamacji i dochodzenia roszczeń, zasady dostępu do tych procedur oraz przyjazna
              wyszukiwarka podmiotów zajmujących się polubownym rozwiązywaniem sporów dostępne są na stronie
              internetowej Urzędu Ochrony Konkurencji i Konsumentów pod adresem: <a href="https://polubowne.uokik.gov.pl/">https://polubowne.uokik.gov.pl/</a>. </li>
            <li className="textTermsP">Przy Prezesie Urzędu Ochrony Konkurencji i Konsumentów działa punkt kontaktowy, którego zadaniem jest
              między innymi udzielanie konsumentom informacji dotyczących pozasądowego rozwiązywania sporów
              konsumenckich. Z punktem konsument może się skontaktować: (1) telefonicznie – dzwoniąc pod numer 22
              55 60 332 lub 22 55 60 333; (2) za pomocą poczty elektronicznej – przesyłając wiadomość na adres:
              <a href="mailto:kontakt.adr@uokik.gov.pl"> kontakt.adr@uokik.gov.pl</a> lub (3) pisemnie lub osobiście – w Centrali Urzędu przy placu Powstańców
              Warszawy 1 w Warszawie (00-030).</li>
            <li className="textTermsP"> Konsument posiada następujące przykładowe możliwości skorzystania z pozasądowych sposobów
              rozpatrywania reklamacji i dochodzenia roszczeń: (1) wniosek o rozstrzygnięcie sporu do stałego
              polubownego sądu konsumenckiego; (2) wniosek w sprawie pozasądowego rozwiązania sporu do
              wojewódzkiego inspektora Inspekcji Handlowej; lub (3) pomoc powiatowego (miejskiego) rzecznika
              konsumentów lub organizacji społecznej, do której zadań statutowych należy ochrona konsumentów (m.
              in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich). Porady udzielane są między innymi
              mailowo pod adresem <a href="mailto:porady@dlakonsumentow.pl">porady@dlakonsumentow.pl</a> oraz pod numerem infolinii konsumenckiej 801 440
              220 (infolinia czynna w dni robocze, w godzinach 8:00-18:00, opłata za połączenie wg taryfy operatora).
            </li>
            <li className="textTermsP">Pod adresem <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a> dostępna jest platforma internetowego systemu rozstrzygania
              sporów pomiędzy konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR). Platforma ODR
              stanowi interaktywną i wielojęzyczną stronę internetową z punktem kompleksowej obsługi dla
              konsumentów i przedsiębiorców dążących do pozasądowego rozstrzygnięcia sporu dotyczącego
              zobowiązań umownych wynikających z internetowej umowy sprzedaży lub umowy o świadczenie usług
              (więcej informacji na stronie samej platformy lub pod adresem internetowym Urzędu Ochrony Konkurencji
              i Konsumentów: <a href="https://polubowne.uokik.gov.pl/platforma-odr,161,pl.html">https://polubowne.uokik.gov.pl/platforma-odr,161,pl.html</a>).
            </li>
          </ol>
          <h2 className="textTermsTitle">14) PRZERWY I AWARIE TECHNICZNE</h2>
          <ol>
            <li className="textTermsP">Usługodawca dokłada należytych starań, aby zapewnić prawidłowe i nieprzerwane funkcjonowanie
              Aplikacji Meetz oraz jej Usług Elektronicznych. Z uwagi na złożoność oraz stopień skomplikowania Aplikacji
              oraz jej Usług Elektronicznych, a także z uwagi na czynniki zewnętrzne pozostające poza kontrolą
              §
              Strona 14 z 23
              Usługodawcy (np. ataki DDOS – distributed denial of service), możliwe jest jednak wystąpienie błędów oraz
              awarii technicznych uniemożliwiających lub ograniczających w jakikolwiek sposób funkcjonowanie Aplikacji
              oraz możliwość korzystania z jej Usług Elektronicznych. Usługodawca podejmie w takim wypadku wszelkie
              możliwe działania mające na celu zapewnienie ograniczenia negatywnych skutków takich zdarzeń w jak
              największym stopniu.</li>
            <li className="textTermsP">Usługodawca informuje niezwłocznie Użytkowników o wszelkich błędach oraz awariach technicznych,
              o których w mowa powyżej oraz przewidywanym terminie ich usunięcia.</li>
            <li className="textTermsP"> Poza przerwami spowodowanymi błędami i awariami technicznymi mogą wystąpić także inne przerwy
              techniczne, w czasie których Usługodawca podejmuje działania mające na celu rozwój Aplikacji oraz jej
              Usług Elektronicznych oraz ich zabezpieczenie przed błędami i awariami technicznymi. </li>
            <li className="textTermsP">Usługodawca planuje przerwy techniczne w taki sposób, aby były one jak najmniej uciążliwe dla
              Użytkowników, w szczególności aby planowane były na pory o mniejszym natężeniu ruchu w Aplikacji (np.
              godziny nocne) i jedynie na czas niezbędny do dokonania koniecznych działań przez Usługodawcę.
              Usługodawca informuje Użytkowników o planowanych przerwach technicznych z odpowiednim
              wyprzedzeniem, podając także przewidywaną długość trwania planowanej przerwy. </li>
            <li className="textTermsP"> Usługodawca nie ponosi wobec Użytkownika odpowiedzialności za szkody i niewypełnienie zobowiązań
              wynikających z wszelkich błędów i awarii technicznych oraz przerw technicznych, o których mowa
              w niniejszym punkcie 14. Regulaminu. Niniejszy punkt 14. Regulaminu nie ma na celu wyłączać ani
              ograniczać ewentualnej odpowiedzialności Usługodawcy Aplikacji Meetz wobec Użytkownika będącego
              konsumentem przewidzianej przez powszechnie obowiązujące przepisy prawa.</li>
          </ol>
          <h2 className="textTermsTitle">15) LICENCJA NA KORZYSTANIE Z APLIKACJI MEETZ</h2>
          <ol>
            <li className="textTermsP"> Prawa autorskie oraz prawa własności intelektualnej do Aplikacji Meetz jako całości oraz jej poszczególnych
              elementów, w tym treści, grafik, utworów, wzorów i znaków dostępnych w jej ramach należą do
              Usługodawcy lub innych uprawnionych podmiotów trzecich i objęte są ochroną przepisów Prawa
              Autorskiego oraz innych przepisów powszechnie obowiązującego prawa. Ochrona przyznana Aplikacji
              obejmuje wszystkie formy jej wyrażenia.</li>
            <li className="textTermsP">Aplikację należy traktować podobnie jak każdy inny utwór podlegający ochronie prawnoautorskiej.
              Użytkownik nie ma prawa kopiować Aplikacji, za wyjątkiem przypadków dozwolonych przepisami
              bezwzględnie wiążącego prawa. Użytkownik zobowiązuje się także nie modyfikować, adaptować,
              tłumaczyć, odkodowywać, dekompilować, dezasemblować lub w jakikolwiek inny sposób starać się ustalić
              kod źródłowy Aplikacji, za wyjątkiem przypadków dozwolonych przepisami bezwzględnie wiążącego prawa.
            </li>
            <li className="textTermsP">Użytkownik korzystający z Aplikacji nie otrzymuje na własność żadnych praw autorskich do Aplikacji.
              Użytkownikowi udzielana jest jedynie – na warunkach wskazanych w Regulaminie – niezbywalna, ważna na
              całym świecie i niewyłączna licencja uprawniająca go do korzystania z Aplikacji w sposób zgodny z jej
              przeznaczeniem i tematyką, z niniejszym Regulaminem oraz w sposób zgodny z prawem i dobrymi
              obyczajami, mając na uwadze poszanowanie dóbr osobistych, danych osobowych oraz praw autorskich i
              własności intelektualnej Usługodawcy, innych Użytkowników oraz osób i podmiotów trzecich.</li>
            <li className="textTermsP">W ramach udzielonej licencji Użytkownik jest uprawniony do korzystania z Aplikacji poprzez jej pobieranie,
              instalowanie, trwałe lub czasowe zwielokrotnianie w pamięci urządzenia mobilnego Użytkownika,
              a także odtwarzanie, stosowanie i wyświetlanie Aplikacji w zakresie niezbędnym do korzystania ze
              wszystkich funkcjonalności i zasobów Aplikacji zgodnie z jej przeznaczeniem i tematyką oraz wyłącznie dla
              własnego, osobistego użytku Użytkownika, w zakresie określonym niniejszym Regulaminem oraz przepisami
              Prawa Autorskiego, z wyłączeniem zastosowania w celach komercyjnych.</li>
            <li className="textTermsP">Wszelkie prawa poza powyższymi nieudzielone Użytkownikowi w sposób wyraźny są zastrzeżone przez
              Usługodawcę, w szczególności Użytkownik nie jest uprawniony do (1) rozpowszechniania Aplikacji, w tym
              najmu i użyczenia Aplikacji lub jej kopii w jakiejkolwiek formie; (2) sublicencjonowania Aplikacji (w tym
              udzielania innym osobom dostępu do korzystania z Aplikacji); (3) wprowadzania Aplikacji do obrotu, w tym
              użyczenia lub najmu; oraz (4) ingerencji w kod źródłowy i strukturę Aplikacji (w tym dokonywania
              opracowań Aplikacji oraz rozpowszechniania, sublicencjonowania lub wprowadzania do obrotu tych
              opracowań w jakiejkolwiek formie).</li>
            <li className="textTermsP">Poszczególne elementy Aplikacji mogą podlegać odrębnym regulaminom i licencjom korzystania – w takim
              wypadku Użytkownik obowiązany jest ich także przestrzegać.</li>
            <li className="textTermsP">Usługodawca zastrzega sobie prawo wypowiedzenia niniejszej licencji w wypadku naruszenia jej warunków
              przez Użytkownika, w tym także w odniesieniu do konkretnych urządzeń, z których dokonano naruszenia.
              Wypowiedzenie licencji może nastąpić niezależnie od zawieszenia lub usunięcia Konta Użytkownika na
              podstawie pkt. 5.7 – 5.11 Regulaminu.
            </li>
            <li className="textTermsP">Znaki handlowe Usługodawcy oraz podmiotów trzecich powinny być używane zgodnie z obowiązującymi
              przepisami prawa.
            </li>
          </ol>
          <h2 className="textTermsTitle">16) POSTANOWIENIA KOŃCOWE</h2>
          <ol>
            <li className="textTermsP">Umowy zawierane na podstawie niniejszego Regulaminu zawierane są w języku polskim oraz zgodnie
              z prawem polskim.</li>
            <li className="textTermsP">Zmiany Regulaminu:
            <ol type="a">
              <li className="textTermsP">Usługodawca zastrzega sobie prawo do dokonywania zmian niniejszego Regulaminu z ważnych
                przyczyn, to jest: zmiany przepisów prawa; podlegania obowiązkowi prawnemu lub regulacyjnemu;
                zmiany zakresu lub formy świadczonych Usług Elektronicznych; dodania nowych Usług
                Elektronicznych; rozwoju funkcjonalności i zasobów Aplikacji; zmiany Cennika; zmiany sposobów i
                terminów płatności; konieczności przeciwdziałania nieprzewidzianemu i bezpośredniemu zagrożeniu
                związanemu z ochroną Aplikacji, w tym Usług Elektronicznych i Użytkowników przed oszustwami,
                złośliwym oprogramowaniem, spamem, naruszeniem danych lub innymi zagrożeniami dla
                cyberbezpieczeństwa; zmiany danych Usługodawcy; poprawy jakości obsługi Użytkowników – w
                zakresie, w jakim te zmiany wpływają na realizację postanowień niniejszego Regulaminu.</li>
              <li className="textTermsP">Powiadomienie o proponowanych zmianach wysyłane jest z wyprzedzeniem co najmniej 15 dni
                przed dniem wejścia w życie tych zmian, z zastrzeżeniem, że zmiana może być wprowadzona bez
                zachowania 15-dniowego okresu powiadomienia w przypadku, gdy Usługodawca: (1) podlega
                obowiązkowi prawnemu lub regulacyjnemu, na podstawie którego zobowiązany jest do zmiany
                Regulaminu w sposób, który uniemożliwia mu dotrzymanie 15-dniowego okresu powiadomienia; lub
                (2) musi w drodze wyjątku zmienić swój Regulamin, aby przeciwdziałać nieprzewidzianemu i
                bezpośredniemu zagrożeniu związanemu z ochroną Aplikacji, w tym Usług Elektronicznych i
                Użytkowników przed oszustwami, złośliwym oprogramowaniem, spamem, naruszeniem danych lub
                innymi zagrożeniami dla cyberbezpieczeństwa. W dwóch ostatnich przypadkach, o których mowa w
                zdaniu poprzednim, wprowadzenie zmian następuje ze skutkiem natychmiastowym, chyba że
                możliwe lub konieczne jest zastosowanie dłuższego terminu wprowadzenia zmian, o czym
                każdorazowo powiadamia Usługodawca.</li>
              <li className="textTermsP">W przypadku umów o charakterze ciągłym (np. korzystanie z Konta), Użytkownik ma prawo
                rozwiązać umowę z Usługodawcą przed upływem okresu powiadomienia o proponowanych
                zmianach. Rozwiązanie takie staje się skuteczne w terminie 15 dni od dnia otrzymania
                powiadomienia. W przypadku zawarcia umowy o charakterze ciągłym, zmieniony Regulamin wiąże
                Użytkownika, jeżeli został prawidłowo powiadomiony o zmianach, zgodnie z okresem
                powiadomienia przed ich wprowadzeniem i nie rozwiązał w tym okresie umowy. Dodatkowo, w
                dowolnym momencie po otrzymaniu powiadomienia o zmianach, Użytkownik może zaakceptować
                wprowadzane zmiany i tym samym zrezygnować z dalszego trwania okresu powiadomienia. W
                przypadku zawarcia umowy o innym charakterze niż umowy ciągłe, zmiany Regulaminu nie będą w
                żaden sposób naruszać praw nabytych Użytkownika przed dniem wejścia w życie zmian Regulaminu.</li>
              <li className="textTermsP">. W wypadku gdyby zmiana Regulaminu skutkowała wprowadzeniem jakichkolwiek nowych opłat lub
                podwyższeniem obecnych konsument ma prawo odstąpienia od umowy.</li>
            </ol></li>
            <li className="textTermsP">W sprawach nieuregulowanych niniejszym Regulaminem mają zastosowanie powszechnie obowiązujące
              przepisy prawa polskiego, w szczególności: Kodeks Cywilny; ustawa z dnia 18 lipca 2002 r. o świadczeniu
              usług drogą elektroniczną (t.j. Dz.U. z 2020 r. poz. 344); Ustawa o Prawach Konsumenta; Prawo Autorskie
              oraz inne właściwe przepisy powszechnie obowiązującego prawa.</li>
            <li className="textTermsP">Niniejszy Regulamin nie wyłącza przepisów obowiązujących w państwie zwykłego miejsca pobytu
              konsumenta zawierającego umowę z Usługodawcą, których nie można wyłączyć w drodze umowy.
              Usługodawca gwarantuje w takim wypadku konsumentowi ochronę przyznaną mu na podstawie przepisów,
              których nie można wyłączyć w drodze umowy.</li>
          </ol>
        <div className="hrTerms"></div>
        <div className="spanBold">Dziękujemy za uważną lekturę!</div>
        <div className="textTermsP" style={{margin: '16px 0'}}>W razie pytań jesteśmy zawsze do Państwa dyspozycji.</div>
        <div className="spanBold">Zapraszamy do korzystania z naszej aplikacji mobilnej, <br/> <br/>Zespół Aplikacji Meetz</div>
        </div>
    );
}

export default TermsOfService;
