// TermsOfService.js
import React from 'react';
import {Link} from "react-router-dom";

function PrivatePolicy() {
    return (
        <div className="containerTerms">
          <Link to="/" className="logoTerms" ></Link>
          <h3 className="textTermsTitle">Załącznik numer 1 do Regulaminu</h3>
          <h2 className="textTermsTitle">POLITYKA PRYWATNOŚCI APLIKACJI MEETZ</h2>
          <div className="textTermsP">Prosimy o uważne zapoznanie się z niniejszą polityką prywatności. Polityka prywatności określa zasady dotyczące
            przetwarzania danych osobowych gromadzonych i przetwarzanych podczas korzystania przez Użytkownika
            z Aplikacji Meetz.
          </div>
          <h2 className="textTermsTitle">1) POSTANOWIENIA OGÓLNE</h2>
          <ol>
            <li className="textTermsP">Niniejsza polityka prywatności Aplikacji ma charakter informacyjny, co oznacza, że nie jest ona źródłem
              obowiązków dla Użytkowników Aplikacji. Polityka prywatności zawiera przede wszystkim zasady dotyczące
              przetwarzania danych osobowych przez Administratora w Aplikacji, w tym podstawy, cele i okres
              przetwarzania danych osobowych oraz prawa osób, których dane dotyczą.</li>
            <li className="textTermsP">Administratorem danych osobowych zbieranych za pośrednictwem Aplikacji jest spółka MEETZ SPÓŁKA Z
              OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ z siedzibą w Poznaniu (adres siedziby i adres do korespondencji: ul.
              Juliusza Słowackiego 55/1, 60-521 Poznań), wpisana do rejestru przedsiębiorców Krajowego Rejestru
              Sądowego pod numerem KRS: 0001051530; sąd rejestrowy, w którym przechowywana jest dokumentacja
              spółki: Sąd Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego
              Rejestru Sądowego; kapitał zakładowy w wysokości: 5000,00 zł; NIP 7812055176, REGON 526056312, adres
              poczty elektronicznej: <a href="mailto:support@meetz.life">support@meetz.life</a> oraz numer telefonu: 888 515 811 – zwana dalej
              <span className="spanBold">„Administratorem”</span> i będąca jednocześnie Usługodawcą Aplikacji Meetz.
            </li>
            <li className="textTermsP">Dane osobowe w Aplikacji przetwarzane są przez Administratora zgodnie z obowiązującymi przepisami
              prawa, w szczególności zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
              27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
              sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
              o ochronie danych) – zwane dalej „<span className="spanBold">RODO</span>” lub „<span className="spanBold">Rozporządzenie RODO</span>”. Oficjalny tekst Rozporządzenia
              RODO: <a href="http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679">http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679</a>.</li>
            <li className="textTermsP">Korzystanie z Aplikacji Meetz, w tym dokonywanie zakupów, jest dobrowolne. Podobnie związane z tym
              podanie danych osobowych przez korzystającego z Aplikacji Meetz Użytkownika jest dobrowolne, z
              zastrzeżeniem dwóch wyjątków: (1) <span className="spanBold">zawarcie umowy</span> – niepodanie w przypadkach i w zakresie wskazanym
              w Aplikacji Meetz oraz w Regulaminie Aplikacji Meetz i niniejszej polityce prywatności danych osobowych
              niezbędnych do zawarcia umowy o korzystanie z Aplikacji lub poszczególnych Usług Elektronicznych (np.
              rejestracja Konta, subskrypcja Wersji Premium itp.) skutkuje brakiem możliwości zawarcia umowy oraz
              skorzystania z usług Aplikacji przez Użytkownika. Podanie danych osobowych jest w takim wypadku
              wymogiem umownym i jeżeli osoba, które dane dotyczą, chce skorzystać z danej Usługi Elektronicznej
              dostępnej w Aplikacji, to jest zobowiązana do podania wymaganych danych. Każdorazowo zakres danych
              wymaganych do skorzystania z określonych usług Aplikacji wskazany jest uprzednio w Aplikacji Meetz oraz
              w Regulaminie Aplikacji Meetz; (2) <span className="spanBold">obowiązki ustawowe Administratora</span> – podanie danych osobowych jest
              wymogiem ustawowym wynikającym z powszechnie obowiązujących przepisów prawa nakładających na
              Administratora obowiązek przetwarzania danych osobowych (np. w celu prowadzenia ksiąg podatkowych
              lub rachunkowych) i brak ich podania uniemożliwi Administratorowi wykonanie tychże obowiązków.
            </li>
            <li className="textTermsP">Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których przetwarzane przez
              niego dane osobowe dotyczą, a w szczególności jest odpowiedzialny i zapewnia, że zbierane przez niego
              dane są: (1) przetwarzane zgodnie z prawem; (2) zbierane dla oznaczonych, zgodnych z prawem celów i
              niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami; (3) merytorycznie poprawne i
              adekwatne w stosunku do celów, w jakich są przetwarzane; (4) przechowywane w postaci umożliwiającej
              identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania oraz
              (5) przetwarzane w sposób zapewniający odpowiednie bezpieczeństwo danych osobowych, w tym
              ochronę przed niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz przypadkową utratą,
              zniszczeniem lub uszkodzeniem, za pomocą odpowiednich środków technicznych lub organizacyjnych. </li>
            <li className="textTermsP">Uwzględniając charakter, zakres, kontekst i cele przetwarzania oraz ryzyko naruszenia praw lub wolności
              osób fizycznych o różnym prawdopodobieństwie i wadze zagrożenia, Administrator wdraża odpowiednie
              środki techniczne i organizacyjne, aby przetwarzanie odbywało się zgodnie z Rozporządzeniem RODO i aby
              móc to wykazać. Środki te są w razie potrzeby poddawane przeglądom i uaktualniane. Administrator stosuje
              środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione danych
              osobowych przesyłanych drogą elektroniczną.</li>
            <li className="textTermsP"> Wszelkie słowa, wyrażenia i akronimy występujące w niniejszej polityce prywatności i rozpoczynające się
              dużą literą (np. <span className="spanBold">Usługa Elektroniczna, Użytkownik, Aplikacja</span>) należy rozumieć zgodnie z ich definicją
              zawartą w Regulaminie Aplikacji Meetz. </li>
          </ol>
          <h2 className="textTermsTitle">2) PODSTAWY PRZETWARZANIA DANYCH</h2>
          <ol>
            <li className="textTermsP">Administrator uprawniony jest do przetwarzania danych osobowych w przypadkach, gdy – i w takim
              zakresie, w jakim – spełniony jest co najmniej jeden z poniższych warunków: (1) osoba, której dane dotyczą
              wyraziła zgodę na przetwarzanie swoich danych osobowych w jednym lub większej liczbie określonych
              celów; (2) przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane
              dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy; (3)
              przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze; lub (4)
              przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych
              przez Administratora lub przez stronę trzecią, z wyjątkiem sytuacji, w których nadrzędny charakter wobec
              tych interesów mają interesy lub podstawowe prawa i wolności osoby, której dane dotyczą, wymagające
              ochrony danych osobowych, w szczególności gdy osoba, której dane dotyczą, jest dzieckiem.</li>
            <li className="textTermsP">Przetwarzanie danych osobowych przez Administratora wymaga każdorazowo zaistnienia co najmniej
              jednej z podstaw wskazanych w pkt. 2.1 polityki prywatności. Konkretne podstawy przetwarzania danych
              osobowych Użytkowników Aplikacji Meetz przez Administratora są wskazane w kolejnym punkcie polityki
              prywatności – w odniesieniu do danego celu przetwarzania danych osobowych przez Administratora.
            </li>
          </ol>
          <h2 className="textTermsTitle">3) CEL, PODSTAWA I OKRES PRZETWARZANIA DANYCH W APLIKACJI</h2>
          <ol>
            <li className="textTermsP">Każdorazowo cel, podstawa i okres oraz odbiorcy danych osobowych przetwarzanych przez Administratora
              wynika z działań podejmowanych przez danego Użytkownika w Aplikacji.
            </li>
            <li className="textTermsP">Korzystanie z Aplikacji Meetz nie wymaga podawania przez Użytkownika danych należących do szczególnej
              kategorii danych osobowych w rozumieniu art. 9 Rozporządzenia RODO. Mając jednak na uwadze
              przeznaczenie i tematykę Aplikacji Meetz, Administrator nie wyklucza, że Użytkownicy mogą dzielić się
              informacjami na swój temat, na przykład w ramach profilu Użytkownika, które będą mogły ujawniać np. ich
              preferencje seksualne, poglądy polityczne lub przekonania religijne. W takim wypadku dane osobowe
              szczególnej kategorii podawane są samodzielnie i dobrowolnie przez Użytkownika, który w sposób
              świadomy decyduje o zakresie informacji, jakie ujawnia na swój temat innym Użytkownikom lub
              Administratorowi.</li>
            <li className="textTermsP">Administrator może przetwarzać dane osobowe w Aplikacji w następujących celach, na podstawach oraz
              w okresach wskazanych w poniższej tabeli:
              <div className="divTable" >
                <div className="divTableBody">
                  <div className="divTableRowFirst">
                    <div className="divTableCell">Cel przetwarzania danych</div>
                    <div className="divTableCell">Podstawa prawna przetwarzania danych</div>
                    <div className="divTableCell">Okres przechowywania danych</div>
                  </div>
                  <div className="divTableRow">
                    <div className="divTableCell">Wykonanie umowy o korzystanie z Aplikacji albo innej umowy lub też podjęcie działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy</div>
                    <div className="divTableCell">Artykuł 6 ust. 1 lit. b) Rozporządzenia RODO (umowa) – przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy
                    </div>
                    <div className="divTableCell">Dane są przechowywane przez okres niezbędny do wykonania, rozwiązania lub wygaśnięcia w inny sposób zawartej umowy.</div>
                  </div>
                  <div className="divTableRow">
                    <div className="divTableCell">Korzystanie z funkcjonalności Aplikacji, które mogą wiązać się z ujawnieniem danych wrażliwych
                    </div>
                    <div className="divTableCell">Artykuł 6 ust. 1 lit. a) w zw. z art. 9 ust. 2 lit. a) Rozporządzenia RODO (zgoda na przetwarzanie danych osobowych szczególnej kategorii) – osoba, której dane dotyczą, wyraża zgodę na przetwarzanie jej danych osobowych szczególnej kategorii w celu skorzystania z funkcjonalności Aplikacji, które wiążą się z ujawnieniem tego rodzaju danych innym Użytkownikom lub Administratorowi
                    </div>
                    <div className="divTableCell">Dane przechowywane są do momentu wycofania zgody przez osobę, której dane dotyczą na dalsze przetwarzanie jej danych w tym celu.
                    </div>
                  </div>
                  <div className="divTableRow">
                    <div className="divTableCell">Marketing bezpośredni </div>
                    <div className="divTableCell">Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie uzasadniony interes administratora) – przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora – polegających na dbaniu o interesy i dobry wizerunek Administratora oraz jego Aplikacji
                    </div>
                    <div className="divTableCell">Dane są przechowywane przez okres istnienia prawnie uzasadnionego interesu realizowanego przez Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń Administratora w stosunku do osoby, której dane dotyczą, z tytułu prowadzonej przez Administratora działalności gospodarczej. Okres przedawnienia określają przepisy prawa, w szczególności Kodeksu Cywilnego (podstawowy termin przedawnienia dla roszczeń związanych z prowadzeniem działalności gospodarczej wynosi trzy lata). Administrator nie może przetwarzać danych w celu marketingu bezpośredniego w przypadku wyrażenia skutecznego sprzeciwu w tym zakresie przez osobę, której dane dotyczą.
                    </div>
                  </div>
                  <div className="divTableRow">
                    <div className="divTableCell">Marketing (np. wysyłanie newslettera)</div>
                    <div className="divTableCell">Artykuł 6 ust. 1 lit. a) Rozporządzenia RODO (zgoda) – osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich danych osobowych w celu otrzymywania informacji marketingowych od Administratora
                    </div>
                    <div className="divTableCell">Dane przechowywane są do momentu wycofania zgody przez osobę, której dane dotyczą na dalsze przetwarzanie jej danych w tym celu.
                    </div>
                  </div>
                  <div className="divTableRow">
                    <div className="divTableCell">Prowadzenie ksiąg rachunkowych
                    </div>
                    <div className="divTableCell">Artykuł 6 ust. 1 lit. c) Rozporządzenia RODO (obowiązek prawny) w zw. z art. 74 ust. 2 ustawy o rachunkowości tj. z dnia 30 stycznia 2018 r. (Dz.U. z 2018 r. poz. 395 z późn. zm.) – przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze</div>
                    <div className="divTableCell">Dane są przechowywane przez okres wymagany przepisami prawa nakazującymi Administratorowi przechowywanie ksiąg rachunkowych (5 lat, licząc od początku roku następującego po roku obrotowym, którego dane dotyczą).</div>
                  </div>
                  <div className="divTableRow">
                    <div className="divTableCell">Ustalenie, dochodzenie lub obrona roszczeń jakie może podnosić Administrator lub jakie mogą być podnoszone wobec Administratora </div>
                    <div className="divTableCell">Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie uzasadniony interes administratora) – przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora – polegających na ustaleniu, dochodzeniu lub obronie roszczeń jakie może podnosić Administrator lub jakie mogą być podnoszone wobec Administratora
                    </div>
                    <div className="divTableCell">Dane są przechowywane przez okres istnienia prawnie uzasadnionego interesu realizowanego przez Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń,jakie mogą być podnoszone wobec Administratora (podstawowy termin przedawnienia dla roszczeń wobec Administratora wynosi sześć lat).
                    </div>
                  </div>
                  <div className="divTableRow">
                    <div className="divTableCell">Korzystanie z Aplikacji i zapewnienie jej prawidłowego działania
                    </div>
                    <div className="divTableCell">Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie uzasadniony interes administratora) – przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora – polegających na prowadzeniu i utrzymaniu Aplikacji
                    </div>
                    <div className="divTableCell">Dane są przechowywane przez okres istnienia prawnie uzasadnionego interesu realizowanego przez Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń Administratora w stosunku do osoby, której dane dotyczą, z tytułu prowadzonej przez Administratora działalności gospodarczej. Okres przedawnienia określają przepisy prawa, w szczególności Kodeksu Cywilnego (podstawowy termin przedawnienia dla roszczeń związanych z prowadzeniem działalności gospodarczej wynosi trzy lata).
                    </div>
                  </div>
                  <div className="divTableRow">
                    <div className="divTableCell">Prowadzenie statystyk i analiza ruchu w Aplikacji
                    </div>
                    <div className="divTableCell">Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie uzasadniony interes administratora) – przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów Administratora – polegających na prowadzeniu statystyk i analizie ruchu w Aplikacji celem poprawy funkcjonowania Aplikacji</div>
                    <div className="divTableCell">Dane są przechowywane przez okres istnienia prawnie uzasadnionego interesu realizowanego przez Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń Administratora w stosunku do osoby, której dane dotyczą, z tytułu prowadzonej przez Administratora działalności gospodarczej. Okres przedawnienia określają przepisy prawa, w szczególności Kodeksu Cywilnego (podstawowy termin przedawnienia dla roszczeń związanych z prowadzeniem działalności gospodarczej wynosi trzy lata).
                    </div>
                  </div>
                </div>
              </div>            </li>
          </ol>
          <h2 className="textTermsTitle">4) ODBIORCY DANYCH W APLIKACJI</h2>
          <ol>
            <li className="textTermsP">Dla prawidłowego funkcjonowania Aplikacji Meetz, w tym świadczenia Usług Elektronicznych, konieczne
              jest korzystanie przez Administratora z usług podmiotów zewnętrznych (takich jak np. dostawca
              oprogramowania, podmiot obsługujący płatności). Administrator korzysta wyłącznie z usług takich
              podmiotów przetwarzających, którzy zapewniają wystarczające gwarancje wdrożenia odpowiednich
              środków technicznych i organizacyjnych, tak by przetwarzanie spełniało wymogi Rozporządzenia RODO i
              chroniło prawa osób, których dane dotyczą. </li>
            <li className="textTermsP">Dane osobowe mogą być przekazywane przez Administratora do państwa trzeciego, przy czym
              Administrator zapewnia, że w takim przypadku odbywać się to będzie zgodnie z Rozporządzeniem RODO w
              stosunku do państwa zapewniającego odpowiedni stopień ochrony, a w przypadku pozostałych państw –
              na podstawie standardowych klauzul ochrony danych, a osoba której dane dotyczą, ma możliwość uzyskania
              kopii swoich danych. Administrator przekazuje zebrane dane osobowe jedynie w przypadku oraz w zakresie
              niezbędnym do zrealizowania danego celu przetwarzania danych zgodnego z niniejszą polityką prywatności.</li>
            <li className="textTermsP">Przekazanie danych przez Administratora nie następuje w każdym wypadku i nie do wszystkich wskazanych
              w polityce prywatności odbiorców lub kategorii odbiorców – Administrator przekazuje dane wyłącznie
              wtedy, gdy jest to niezbędne do realizacji danego celu przetwarzania danych osobowych i tylko w zakresie
              niezbędnym do jego zrealizowania.</li>
            <li className="textTermsP">Dane osobowe Użytkowników Aplikacji Meetz mogą być przekazywane następującym odbiorcom lub
              kategoriom odbiorców:
            <ol type="a">
              <li className="textTermsP">
                <span className="spanBold">podmioty obsługujące płatności elektroniczne lub kartą płatniczą </span> – w przypadku Użytkownika,
                który dokonuje zakupu odpłatnych funkcjonalności w Aplikacji i korzysta z płatności elektronicznych
                lub kartą płatniczą, Administrator udostępnia zebrane dane osobowe Użytkownika wybranemu
                podmiotowi obsługującemu powyższe płatności w Aplikacji na zlecenie Administratora w zakresie
                niezbędnym do obsługi płatności realizowanej przez Użytkownika.
              </li>
              <li className="textTermsP">
                <span className="spanBold">dostawcy usług księgowych, prawnych i doradczych zapewniający Administratorowi wsparcie
                  księgowe, prawne lub doradcze</span> (w szczególności biuro księgowe, kancelaria prawna lub firma
                windykacyjna) – Administrator udostępnia zebrane dane osobowe Użytkownika wybranemu
                dostawcy działającemu na jego zlecenie jedynie w przypadku oraz w zakresie niezbędnym do
                zrealizowania danego celu przetwarzania danych zgodnego z niniejszą polityką prywatności.
              </li>
              <li className="textTermsP"><span className="spanBold">dostawcy usług zaopatrujący Administratora w rozwiązania techniczne, informatyczne oraz
                organizacyjne, umożliwiające Administratorowi prowadzenie i utrzymywanie Aplikacji oraz
                świadczenie Usług Elektronicznych </span> (w szczególności dostawcy oprogramowania komputerowego do
                prowadzenia Aplikacji Meetz, dostawcy poczty elektronicznej i hostingu oraz dostawcy
                oprogramowania do zarządzania firmą i udzielania pomocy technicznej Administratorowi) –
                Administrator udostępnia zebrane dane osobowe Użytkownika wybranemu dostawcy działającemu
                na jego zlecenie jedynie w przypadku oraz w zakresie niezbędnym do zrealizowania danego celu
                przetwarzania danych zgodnego z niniejszą polityką prywatności.
              </li>
              <li className="textTermsP"> <span className="spanBold">dostawcy umieszczonych w Aplikacji wtyczek społecznościowych, skryptów oraz innych
                podobnych narzędzi umożliwiających Aplikacji pobieranie treści od dostawców wspomnianych
                wtyczek </span> (np. logowanie za pomocą danych logowania do innego serwisu) i przekazywanie w tym celu
                tym dostawcom danych osobowych osoby odwiedzającej, w tym również:
              <ol type="i">
                <li className="textTermsP"> <span className="spanBold">Google Ireland Ltd. </span> – Administrator korzysta w Aplikacji z wtyczek serwisu Google (np.
                  logowanie za pomocą danych logowania do serwisu Google) i w związku z tym gromadzi i
                  udostępnia dane osobowe Użytkownika korzystającego z Aplikacji do Google Ireland Ltd.
                  (Gordon House, Barrow Street, Dublin 4, Irlandia) w zakresie i zgodnie z zasadami prywatności
                  dostępnymi tutaj: <a href="https://policies.google.com/privacy?hl=pl">https://policies.google.com/privacy?hl=pl</a> (dane te obejmują informacje o
                  działaniach w Aplikacji – w tym informacje o urządzeniu, odwiedzanych witrynach, zakupach,
                  wyświetlanych reklamach oraz sposobie korzystania z usług).
                </li>
              </ol></li>
            </ol></li>
          </ol>
          <h2 className="textTermsTitle">5) PROFILOWANIE W APLIKACJI </h2>
          <ol>
            <li className="textTermsP"> Rozporządzenie RODO nakłada na Administratora obowiązek informowania o zautomatyzowanym
              podejmowaniu decyzji, w tym o profilowaniu, o którym mowa w art. 22 ust. 1 i 4 Rozporządzenia RODO,
              oraz – przynajmniej w tych przypadkach – istotne informacje o zasadach ich podejmowania, a także o
              znaczeniu i przewidywanych konsekwencjach takiego przetwarzania dla osoby, której dane dotyczą. Mając
              to na uwadze, Administrator podaje w tym punkcie polityki prywatności informacje dotyczące możliwego
              profilowania. </li>
            <li className="textTermsP">Administrator może korzystać w Aplikacji z profilowania, przy czym decyzje podejmowane na jego
              podstawie przez Administratora nie dotyczą zawarcia lub odmowy zawarcia umowy czy też możliwości
              korzystania z Usług Elektronicznych w Aplikacji. Profilowanie może być wykorzystywane do:
            <ol type="a">
              <li className="textTermsP">Przedstawiania dopasowanych rekomendacji i wyników wyszukiwania obejmujących profile innych
                Użytkowników Aplikacji, które mogą potencjalnie odpowiadać zainteresowaniom lub preferencjom
                danej osoby. Profilowanie polega w takim wypadku na automatycznej analizie preferencji i
                zainteresowań zdefiniowanych na profilu danej osoby, przy czym to dana osoba podejmuje swobodnie decyzję, czy będzie chciała skorzystać z rekomendacji i np. zaprosić Użytkownika do
                znajomych oraz nawiązać z nim kontakt.</li>
              <li className="textTermsP">Prowadzenia marketingu bezpośredniego, czego efektem może być np. przypomnienie danej osobie
                o niedokończonych działaniach w Aplikacji, przyznanie rabatu, przesłanie oferty, która może
                odpowiadać zainteresowaniom lub preferencjom danej osoby lub też zaproponowanie lepszych
                warunków w porównaniu do standardowej oferty Aplikacji. Mimo profilowania to dana osoba
                podejmuje swobodnie decyzję, czy będzie chciała skorzystać np. z otrzymanego w ten sposób rabatu
                czy też oferty. Profilowanie polega w tym wypadku na automatycznej analizie lub prognozie
                zachowania danej osoby w ramach Aplikacji Meetz, np. poprzez analizę dotychczasowej historii
                działań lub zakupów w Aplikacji. Warunkiem takiego profilowania jest posiadanie przez
                Administratora danych osobowych danej osoby, aby móc jej następnie przesłać np. ofertę lub rabat.</li>
            </ol>
            </li>
            <li className="textTermsP">Osoba, której dane dotyczą, ma prawo do tego, by nie podlegać decyzji, która opiera się wyłącznie na
              zautomatyzowanym przetwarzaniu, w tym profilowaniu, i wywołuje wobec tej osoby skutki prawne lub w
              podobny sposób istotnie na nią wpływa.</li>
          </ol>
          <h2 className="textTermsTitle">6) PRAWA OSOBY, KTÓREJ DANE DOTYCZĄ </h2>
          <ol>
            <li className="textTermsP"><span className="spanBold">Prawo dostępu, sprostowania, ograniczenia, usunięcia lub przenoszenia </span> – osoba, której dane dotyczą, ma
              prawo żądania od Administratora dostępu do swoich danych osobowych, ich sprostowania, usunięcia
              („prawo do bycia zapomnianym”) lub ograniczenia przetwarzania oraz ma prawo do wniesienia sprzeciwu
              wobec przetwarzania, a także ma prawo do przenoszenia swoich danych. Szczegółowe warunki
              wykonywania wskazanych wyżej praw wskazane są w art. 15-21 Rozporządzenia RODO. </li>
            <li className="textTermsP"><span className="spanBold">Prawo do cofnięcia zgody w dowolnym momencie </span> – osoba, której dane przetwarzane są przez
              Administratora na podstawie wyrażonej zgody (na podstawie art. 6 ust. 1 lit. a) lub art. 9 ust. 2 lit. a)
              Rozporządzenia RODO), ma prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z
              prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.
            </li>
            <li className="textTermsP"><span className="spanBold">Prawo wniesienia skargi do organu nadzorczego </span> – osoba, której dane przetwarzane są przez
              Administratora, ma prawo wniesienia skargi do organu nadzorczego w sposób i trybie określonym w
              przepisach Rozporządzenia RODO oraz prawa polskiego, w szczególności ustawy o ochronie danych
              osobowych. Organem nadzorczym w Polsce jest Prezes Urzędu Ochrony Danych Osobowych. </li>
            <li className="textTermsP"><span className="spanBold">Prawo do sprzeciwu </span>– osoba, której dane dotyczą, ma prawo w dowolnym momencie wnieść sprzeciw – z
              przyczyn związanych z jej szczególną sytuacją – wobec przetwarzania dotyczących jej danych osobowych
              opartego na art. 6 ust. 1 lit. e) (interes lub zadania publiczne) lub f) (prawnie uzasadniony interes
              administratora), w tym profilowania na podstawie tych przepisów. Administratorowi w takim przypadku nie
              wolno już przetwarzać tych danych osobowych, chyba że wykaże on istnienie ważnych prawnie
              uzasadnionych podstaw do przetwarzania, nadrzędnych wobec interesów, praw i wolności osoby, której
              dane dotyczą, lub podstaw do ustalenia, dochodzenia lub obrony roszczeń.</li>
            <li className="textTermsP"><span className="spanBold">Prawo do sprzeciwu dot. marketingu bezpośredniego </span> – jeżeli dane osobowe są przetwarzane na potrzeby
              marketingu bezpośredniego, osoba, której dane dotyczą, ma prawo w dowolnym momencie wnieść
              sprzeciw wobec przetwarzania dotyczących jej danych osobowych na potrzeby takiego marketingu, w tym
              profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim.</li>
            <li className="textTermsP">W celu realizacji uprawnień, o których mowa w niniejszym punkcie polityki prywatności można kontaktować
              się z Administratorem poprzez przesłanie stosownej wiadomości pisemnie lub pocztą elektroniczną na adres
              Administratora wskazany na wstępie polityki prywatności.
            </li>
          </ol>
          <h2 className="textTermsTitle">7) DANE EKSPLOATACYJNE I ANALITYKA</h2>
          <ol>
            <li className="textTermsP">Administrator może korzystać w Aplikacji Meetz z usług Google Analytics, Universal Analytics dostarczanych
              przez firmę Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irlandia). Usługi te pomagają
              Administratorowi prowadzić statystyki i analizować ruch w Aplikacji. Gromadzone dane przetwarzane są w
              ramach powyższych usług do generowania statystyk pomocnych w administrowaniu Aplikacją i analizie
              ruchu w Aplikacji. Dane te mają charakter zbiorczy. Administrator korzystając z powyższych usług w Aplikacji
              gromadzi takie dane jak źródła i medium pozyskania odwiedzających Aplikację oraz sposób ich zachowania
              w Aplikacji, informacje na temat urządzeń, z których odwiedzają Aplikację, adres IP, dane geograficzne oraz
              dane demograficzne (wiek, płeć) i zainteresowania.</li>
            <li className="textTermsP">W związku z możliwością korzystania przez Administratora w Aplikacji z usług analitycznych dostarczanych
              przez Google Ireland Ltd., Administrator wskazuje, że pełna informacja o zasadach przetwarzania danych
              osób odwiedzających Aplikację przez Google Ireland Ltd. znajduje się w polityce prywatności usług Google
              pod adresem internetowym: <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a>.</li>
          </ol>
          <h2 className="textTermsTitle">8) POSTANOWIENIA KOŃCOWE</h2>
          <div className="textTermsP">Aplikacja może zawierać odnośniki do innych stron internetowych lub aplikacji. Administrator namawia, by po
            przejściu na inne strony zapoznać się z polityką prywatności tam ustaloną. Niniejsza polityka prywatności dotyczy
            tylko Aplikacji Administratora.</div>
        </div>
    );
}

export default PrivatePolicy;


