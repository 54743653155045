import './App.css';
import './MaxWidth992.css';
import './MaxWidth1200.css';
import './MaxWidth1920.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import TermsOfService from './TermsOfService';
import PrivatePolicy from './PrivatePolicy';
function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivatePolicy />} />
        </Routes>
      </Router>
)
}

export default App;
